import { gql, useQuery } from "@apollo/client";
import { ServicePointView, Modal } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";

export const GET_CUSTOMER = gql`
  query GetServicePoint($id: ID!) {
    servicePoint: getServicePoint(id: $id) {
      _id
      code
      qrCode
      propertyCode
      customerCode
      accountCode
      meterCode
      serviceType
      serviceClass
      geoCode
      meterLocation
      transformerNumber
      contractedDemand
      status
      customer {
        _id
        representative {
          title
          fullName
          phoneNumber
          emailAddress
          profileImageUrl
          ghanaCardNumber
        }
        organization {
          name
          organizationRegistrationNumber
        }
        customerType
        code
      }
      meta {
        lastReadingDate
        lastBillAmount
        lastBillDate
        lastReadingValue
      }
      tariffClass {
        name
        serviceClass
      }
      property {
        regionName
        districtName
        blockName
        roundName
        plotCode
        itineraryCode
        code
        _id
      }
    }
  }
`;

export default function ViewCustomerContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const { data, loading } = useQuery(GET_CUSTOMER, {
    variables: {
      id: searchParams.id,
    },
    notifyOnNetworkStatusChange: false,
  });

  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      title="Customer Information"
      description="Details of customer are shown below"
    >
      <ServicePointView servicePoint={data?.servicePoint} />
    </Modal>
  );
}
