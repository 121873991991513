import { gql, useQuery } from "@apollo/client";
import { Shimmers, StatsPageLoader } from "components";
import { FC, useMemo, useRef } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { getPeriod, useUpdatedSummaryData } from "utils";
import numeral from "numeral";
import lodash from "lodash";
import TableBodyComponent from "components/tables/table-body";
import CustomerFilter from "components/layouts/filter";
import moment from "moment";
const DATE_FORMAT = "YYYY-MM-DD";

const SUMMARY = gql`
  query GetOverallSummary(
    $fromDate: Date
    $toDate: Date
    $region: ID
    $district: ID
    $categories: [String]
    $contractStatuses: [String]
    $serviceTypes: [String]
    $serviceClasses: [String]
    $customerTypes: [String]
    $tariffClasses: [String]
    $meterSystems: [String]
    $meterBrands: [String]
    $type: String
  ) {
    rows: getOverallSummary(
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
      categories: $categories
      contractStatuses: $contractStatuses
      serviceTypes: $serviceTypes
      serviceClasses: $serviceClasses
      customerTypes: $customerTypes
      tariffClasses: $tariffClasses
      meterSystems: $meterSystems
      meterBrands: $meterBrands
      type: $type
    ) {
      _id
      label
      data
    }
  }
`;

const NewCustomersGeneralPage: FC = () => {
  const tableRef = useRef(null);
  const searchParams = useSearch<LocationGenerics>();

  const filter = useMemo(
    () => ({
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
      type: "contractStatus",
      ...searchParams?.filter,
    }),
    [searchParams]
  );

  const { data, loading, networkStatus } = useQuery(SUMMARY, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: "network-only",
  });

  const { records, totals } = useUpdatedSummaryData(data);

  return (
    <div className="flex-1 flex flex-col mx-auto min-w-0 min-h-full">
      <CustomerFilter
        filters={[
          "contractStatuses",
          "serviceTypes",
          "serviceClasses",
          "customerTypes",
          "tariffClasses",
          "meterSystems",
          "meterBrands",
          "serviceTypes",
        ]}
      />
      {loading ? (
        <StatsPageLoader />
      ) : (
        <div className="flex-1 min-h-full min-w-0 py-4 sm:py-6 px-6 overflow-hidden overflow-y-auto light flex flex-col">
          <div className="flex flex-col">
            <div className="overflow-y-auto">
              <TableBodyComponent
                title={`Customers Per ${searchParams?.region ? "District" : "Region"
                  }`}
                exportFileName={`Customers Per ${searchParams?.region ? "District" : "Region"
                  } For ${getPeriod(searchParams)}`}
                exportTypes={["EXCEL", "PDF"]}
                noSpace={true}
                loading={loading && ![4, 6].includes(networkStatus)}
                data={{
                  ...records,
                  rows: [
                    ...records.rows,
                    {
                      _id: "total",
                      label: {
                        _id: "total",
                        code: "total",
                        name: "Total",
                      },
                      data: totals.data,
                    },
                  ],
                }}
                renderColumns={() => (
                  <>
                    <tr className="hidden print:table-row">
                      <th
                        scope="col"
                        colSpan={2}
                        className="sticky top-0 border-t border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-center text-md font-bold text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Customers Per{" "}
                        {searchParams?.region ? "District" : "Region"} For{" "}
                        {getPeriod(searchParams)}
                      </th>
                    </tr>
                    <tr>
                      {!!searchParams.region ? (
                        <th
                          scope="col"
                          className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          District
                        </th>
                      ) : (
                        <th
                          scope="col"
                          className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Region
                        </th>
                      )}
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Count
                      </th>
                    </tr>
                  </>
                )}
                renderLoader={() => (
                  <tr className="border-t border-gray-200 print:border-gray-700">
                    <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                      <Shimmers.SingleShimmer />
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                      <Shimmers.SingleShimmer />
                    </td>
                  </tr>
                )}
                renderItem={(item) => (
                  <tr
                    key={item._id}
                    className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                  >
                    <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                      {item?.label?.name || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                      {numeral(lodash.get(item?.data, "Total")).format("0,0") ||
                        "N/A"}
                    </td>
                  </tr>
                )}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewCustomersGeneralPage;
