import { FC } from "react"
import { IResolveInspectionServiceOrderFormSchema } from "./schema"
import { ICurrentConfig } from "apollo/cache/config";
import { wrapImage } from "utils";

const PropertyInfoSummary: FC<{ data: IResolveInspectionServiceOrderFormSchema["propertyInfo"]; config: ICurrentConfig }> = ({ data, config: { dateFormat } }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className=''>
      <span className="text-xs font-light">Location Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Region
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.region?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            District
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.district?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Ghana Post Address
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.ghanaPostAddress || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className='pt-6'>
      <span className="text-xs font-light">Premise Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Premise Type
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.premiseType?.name || "N/A"}
          </div>
        </div>
        <div className="col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Premise Category
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.premiseCategory?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Activity
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.activity?.name || "N/A"}
          </div>
        </div>
        <div className="col-span-2">
          <span className="block text-sm font-light text-gray-700">
            Sub Activity
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.subActivity?.name || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className='pt-6'>
      <span className="text-xs font-light">Premise Information</span>
      <div className='grid gap-6 mt-2'>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Premise Images
          </span>
          {data?.propertyImageUrls?.length ? (
          <div className='mt-2 grid grid-cols-3 gap-3'>
            {data?.propertyImageUrls?.map((image) => wrapImage(
              <img
                src={image}
                alt={"back"}
                className="w-full h-56 text-xs object-cover object-center rounded"
              />
            ))}
          </div>
          ) : (
            <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
              Premise images not provided
            </div>
          )}
        </div>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Site Plan Document
          </span>
          {data?.sitePlanDocumentUrl ? (
            <div className="mt-2">
              {wrapImage(
                <img
                  src={data?.sitePlanDocumentUrl}
                  alt={""}
                  className="w-full h-64 text-xs"
                />
              )}
            </div>
          ) : (
            <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
              Site plan not provided
            </div>
          )}
        </div>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Proposed Network Design Drawings
          </span>
          {data?.networkDesignDrawingUrls?.length ? (
          <div className='mt-2 grid grid-cols-3 gap-3'>
            {data?.networkDesignDrawingUrls?.map((image) => wrapImage(
              <img
                src={image}
                alt={"back"}
                className="w-full h-56 text-xs object-cover object-center rounded"
              />
            ))}
          </div>
          ) : (
            <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
              Network Design Drawings not provided
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)

export default PropertyInfoSummary