import { gql, useQuery } from "@apollo/client";
import {
  LineChart,
  Shimmers,
  StatsPageLoader,
} from "components";
import { FC, useMemo } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTableData } from "utils";
import numeral from "numeral";
import lodash from "lodash";
import moment from "moment";
import TableBodyComponent from "components/tables/table-body";
const DATE_FORMAT = "YYYY-MM-DD";

const SUMMARY = gql`
  query GetRegularizationDailySummary(
    $fromDate: Date!
    $toDate: Date!
    $region: ID
    $district: ID
  ) {
    summary: getRegularizationDailySummary(
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
    ) {
      _id
      date
      total
      urban
      rural
      periUrban
      residential
      nonResidential
      totalConsumption
      totalBilled
    }
  }
`;


const GET_DISTRICT_DATA = gql`
  query GetDistricts($region: ID) {
    rows: getDistricts(region: $region) {
      _id
      code
      name
    }
  }
`;

const GET_REGION_DATA = gql`
  query getRegions {
    rows: getRegions {
      _id
      code
      name
    }
  }
`;

const stats = [
  {
    id: "standard",
    name: "Standard",
    value: 4505
  },
  {
    id: "express",
    name: "Express",
    value: 4505
  },
  {
    id: "premium",
    name: "Premium",
    value: 4505
  },
  {
    id: "total",
    name: "Total",
    value: 4505
  },
];

const NewCustomersServiceClassPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();

  const filter = useMemo(
    () => ({
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      serviceType: searchParams.serviceType || undefined,
      tariffClass: searchParams.tariffClass || undefined,
    }),
    [searchParams]
  );

  const {
    data: dataDistricts,
    loading: loadingDistricts,
    networkStatus: networkStatusDistricts,
  } = useQuery(GET_DISTRICT_DATA, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  const {
    data: dataRegions,
    loading: loadingRegions,
    networkStatus: networkStatusRegions,
  } = useQuery(GET_REGION_DATA, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  const recordsDistricts = useTableData(dataDistricts);
  const recordsRegions = useTableData(dataRegions);

  const { data: graphData, loading } = useQuery(SUMMARY, {
    variables: {
      fromDate: moment().startOf("month").toDate(),
      toDate: moment().endOf("month").toDate(),
    },
  });
  const keyedGraphData = useMemo(
    () =>
      lodash.keyBy(graphData?.summary, (datum) =>
        moment(datum.date).format(DATE_FORMAT)
      ),
    [graphData?.summary]
  );

  if(loading) {
    return <StatsPageLoader />
  }

  return (
    <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 px-6 overflow-hidden overflow-y-auto light flex flex-col">
      <div className="">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Summary
          </h3>
          <div className="mt-3 ">
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white p-4 shadow"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="flex justify-between items-end">
                    <p className="text-xl font-light print:font-bold  text-gray-900">
                      {numeral(item.value).format("0,0")}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <LineChart
          title="Customers Per Request Category Per Day"
          exportFileName="Customers Per Request Category Per Day"
          label="Request Category"
          keys={[
            {
              color: "#2563eb",
              bgColor: "bg-blue-600",
              id: "Standard",
              label: "Standard",
              value: 400,
            },
            {
              color: "#0d9488",
              bgColor: "bg-teal-600",
              id: "Express",
              label: "Express",
              value: 300,
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "Premium",
              label: "Premium",
              value: 300,
            },
          ]}
          data={lodash.times(
            moment(searchParams.toDate, DATE_FORMAT).diff(
              moment(searchParams.fromDate, DATE_FORMAT),
              "days"
            ) + 1,
            (idx) => {
              const currentDate = moment(searchParams.fromDate)
                .add(idx, "days")
                .format(DATE_FORMAT);
              return {
                value: {
                  Standard: lodash.random(10, 100, false), // lodash.get(keyedGraphData, currentDate),
                  Express: lodash.random(10, 100, false), // lodash.get(keyedGraphData, currentDate),
                  Premium: lodash.random(10, 100, false), // lodash.get(keyedGraphData, currentDate),
                },
                day: currentDate,
              };
            }
          )}
        />
      </div>
      {!searchParams.district && (
      <div className="flex flex-row mt-6 space-x-6">
        <div className="flex-1">
          <div className="flex flex-col">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Customers Per Request Category Per {searchParams?.region ? "District" : "Region"} 
            </h3>
            <div className="mt-3 overflow-y-auto rounded-lg bg-white shadow">
              <TableBodyComponent
                noSpace={true}
                loading={
                  searchParams?.region
                    ? loadingDistricts &&
                      ![4, 6].includes(networkStatusDistricts)
                    : loadingRegions && ![4, 6].includes(networkStatusRegions)
                }
                data={
                  searchParams?.region
                    ? {
                        ...recordsDistricts,
                        rows: [
                          ...recordsDistricts.rows,
                          {
                            _id: "total",
                            code: "Total",
                            name: "Total",
                          },
                        ],
                      }
                    : {
                        ...recordsRegions.rows,
                        rows: [
                          ...recordsRegions.rows,
                          {
                            _id: "total",
                            code: "Total",
                            name: "Total",
                          },
                        ],
                      }
                }
                renderColumns={() => (
                  <tr>
                  {!!searchParams.region ? (
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      District
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Region
                    </th>
                  )}
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Standard
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Express
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Premium
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      Total
                    </th>
                  </tr>
                )}
                renderLoader={() => (
                  <tr className="border-t border-gray-200 print:border-gray-700">
                    <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                      <Shimmers.SingleShimmer />
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                      <Shimmers.SingleShimmer />
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                      <Shimmers.SingleShimmer />
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                      <Shimmers.SingleShimmer />
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                      <Shimmers.SingleShimmer />
                    </td>
                  </tr>
                )}
                renderItem={(item) => (
                  <tr
                    key={item._id}
                    className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                  >
                  {!!searchParams.region ? (
                    <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                      {item?.name || "N/A"}
                    </td>
                  ) : (
                    <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                      {item?.name || "N/A"}
                    </td>
                  )}
                    <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                      {numeral(item?.totalTerminated).format("0,0") || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                      {numeral(item?.totalTerminated).format("0,0") || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                      {numeral(item?.totalTerminated).format("0,0") || "N/A"}
                    </td>
                    <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                      {numeral(item?.totalTerminated).format("0,0") || "N/A"}
                    </td>
                  </tr>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default NewCustomersServiceClassPage;
