import { gql, useQuery } from "@apollo/client";

const GET_CUSTOMERS = gql`
  query GetServicePoints(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $block: ID
    $round: ID
    $plot: ID
    $itinerary: ID
    $property: ID
    $tariffClass: ID
    $tariffClasses: [ID]
    $meterSystem: ID
    $meterSystems: [ID]
    $meterBrand: ID
    $meterBrands: [ID]
    $meterModel: ID
    $meterModels: [ID]
    $serviceClass: ServiceClass
    $serviceClasses: [ServiceClass]
    $customerType: CustomerType
    $customerTypes: [CustomerType]
    $serviceType: ServiceType
    $serviceTypes: [ServiceType]
    $setupType: SetupType
    $setupTypes: [SetupType]
    $initialSetupDate: Date
    $contractStartDate: Date
    $contractStatus: ServicePointStatus
    $contractStatuses: [ServicePointStatus]
    $categories: [String]
    $fromSetupDate: Date
    $toSetupDate: Date
  ) {
    rows: getServicePoints(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      itinerary: $itinerary
      property: $property
      tariffClass: $tariffClass
      tariffClasses: $tariffClasses
      meterSystem: $meterSystem
      meterSystems: $meterSystems
      meterBrand: $meterBrand
      meterBrands: $meterBrands
      meterModel: $meterModel
      meterModels: $meterModels
      serviceClass: $serviceClass
      serviceClasses: $serviceClasses
      customerType: $customerType
      customerTypes: $customerTypes
      serviceType: $serviceType
      serviceTypes: $serviceTypes
      setupType: $setupType
      setupTypes: $setupTypes
      initialSetupDate: $initialSetupDate
      contractStartDate: $contractStartDate
      status: $contractStatus
      statuses: $contractStatuses
      categories: $categories
      fromSetupDate: $fromSetupDate
      toSetupDate: $toSetupDate
      excludePending: true
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      createdAt
      updatedAt
      accountCode
      customerCode
      meterCode
      geoCode
      propertyCode
      customerType
      serviceType
      serviceClass
      initialSetupDate
      contractStartDate
      setupType
      customer {
        _id
        code
        customerType
        organization {
          name
        }
        representative {
          fullName
          emailAddress
          phoneNumber
          profileImageUrl
        }
      }
      status
      tariffClass {
        name
        code
      }
      ghanaPostAddress
      property {
        address
      }
    }
    count: getServicePointsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      itinerary: $itinerary
      property: $property
      tariffClass: $tariffClass
      tariffClasses: $tariffClasses
      meterSystem: $meterSystem
      meterSystems: $meterSystems
      meterBrand: $meterBrand
      meterBrands: $meterBrands
      meterModel: $meterModel
      meterModels: $meterModels
      serviceClass: $serviceClass
      serviceClasses: $serviceClasses
      customerType: $customerType
      customerTypes: $customerTypes
      serviceType: $serviceType
      serviceTypes: $serviceTypes
      setupType: $setupType
      setupTypes: $setupTypes
      initialSetupDate: $initialSetupDate
      contractStartDate: $contractStartDate
      status: $contractStatus
      statuses: $contractStatuses
      categories: $categories
      fromSetupDate: $fromSetupDate
      toSetupDate: $toSetupDate
      excludePending: true
    )
  }
`;

export const useServicePoints = (variables: any) => {
  const { data, ...rest } = useQuery(GET_CUSTOMERS, {
    variables,
    notifyOnNetworkStatusChange: true,
  });
  const servicePoints = data?.rows || [];
  const count = data?.count || 0;
  return { servicePoints, count, ...rest };
};
