import { Shimmers, TableComponent } from "components";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, classNames, useTableData, getPeriod, useDownloadFile } from "utils";
import config from "config";
import ViewCustomerContainer from "./view";
import { Avatar } from "components/core";
import { Action } from "components/buttons/action-button";
import { useServicePoints } from "./hooks";
import CustomerFilter from "components/layouts/filter";
import moment from "moment";
import { useLazyQuery, gql } from "@apollo/client";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
const DATE_FORMAT = "YYYY-MM-DD";

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query(
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $block: ID
    $round: ID
    $plot: ID
    $itinerary: ID
    $property: ID
    $tariffClass: ID
    $tariffClasses: [ID]
    $meterSystem: ID
    $meterSystems: [ID]
    $meterBrand: ID
    $meterBrands: [ID]
    $meterModel: ID
    $meterModels: [ID]
    $serviceClass: ServiceClass
    $serviceClasses: [ServiceClass]
    $customerType: CustomerType
    $customerTypes: [CustomerType]
    $serviceType: ServiceType
    $serviceTypes: [ServiceType]
    $setupType: SetupType
    $setupTypes: [SetupType]
    $initialSetupDate: Date
    $contractStartDate: Date
    $contractStatus: ServicePointStatus
    $contractStatuses: [ServicePointStatus]
    $categories: [String]
    $fromSetupDate: Date
    $toSetupDate: Date
    $fileName: String
  ) {
    url: getServicePointsExportUrl(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      block: $block
      round: $round
      plot: $plot
      itinerary: $itinerary
      property: $property
      tariffClass: $tariffClass
      tariffClasses: $tariffClasses
      meterSystem: $meterSystem
      meterSystems: $meterSystems
      meterBrand: $meterBrand
      meterBrands: $meterBrands
      meterModel: $meterModel
      meterModels: $meterModels
      serviceClass: $serviceClass
      serviceClasses: $serviceClasses
      customerType: $customerType
      customerTypes: $customerTypes
      serviceType: $serviceType
      serviceTypes: $serviceTypes
      setupType: $setupType
      setupTypes: $setupTypes
      initialSetupDate: $initialSetupDate
      contractStartDate: $contractStartDate
      status: $contractStatus
      statuses: $contractStatuses
      categories: $categories
      fromSetupDate: $fromSetupDate
      toSetupDate: $toSetupDate
      excludePending: true
      fileName: $fileName
    )
  }
`;

const CustomersPage: FC = () => {
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "_HasleFree.xlsx",
  });
  const handleDownloadItem = () => {
    createDataExport({
      variables: filter,
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data.url) {
        downloadAction(`${data.url}`);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    });
  };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: "".toString(),
      searchFields: ["code", "meterCode", "accountCode", "geoCode"],
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      block: searchParams.block || undefined,
      round: searchParams.round || undefined,
      plot: searchParams.plot || undefined,
      itinerary: searchParams.itinerary || undefined,
      property: searchParams.property || undefined,
      fromSetupDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toSetupDate: moment(searchParams.toDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      ...searchParams?.filter,
      ...(searchParams?.filter?.categories?.length
        ? {
            categories: searchParams?.filter?.categories,
          }
        : {
            categories: [
              "Existing",
              "NewService",
              "NotMigrated",
              "Shep",
              "Regularization",
            ],
          }),
          fileName: `Customer Report For ${getPeriod(
            searchParams
          )}`
    }),
    [searchParams]
  );

  const { servicePoints, count, loading, refetch, networkStatus } =
    useServicePoints(filter);

  const records = useTableData({ rows: servicePoints, count });

  const onSearch = (searchValue: string, searchField: string) => {
    refetch({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: String(searchValue),
      searchFields: [searchField],
      sort: searchParams.sort || "",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      block: searchParams.block || undefined,
      round: searchParams.round || undefined,
      plot: searchParams.plot || undefined,
      itinerary: searchParams.itinerary || undefined,
      property: searchParams.property || undefined,
      fromSetupDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toSetupDate: moment(searchParams.toDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      ...searchParams?.filter,
    });
  };

  const [createDataExport, { loading: gettingUrl }] = useLazyQuery(
    GET_SERVICE_ORDER_EXPORT_URL,
    {
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <>
      <div className="flex-1 flex flex-col mx-auto min-w-0 min-h-full overflow-hidden">
        <CustomerFilter
          filters={[
            "categories",
            "contractStatuses",
            "serviceTypes",
            "customerTypes",
            "tariffClasses",
            "meterSystems",
            "meterBrands",
          ]}
        />
        <div className="flex-1 min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"customers"}
            refetch={refetch}
            onSearchSubmit={onSearch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2">
               <button
                  type="button"
                  onClick={wrapClick(handleDownloadItem)}
                  disabled={gettingUrl || downloadLoading}
                  className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  <CloudArrowDownIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {gettingUrl
                    ? "Processing file for download"
                    : downloadLoading
                    ? "Exporting"
                    : "Export To Excel"}
                </button>
                 {/* <SearchSelectInput
                  id="status"
                  labelHidden={true}
                  options={[
                    { label: { title: "Low" }, value: "Low" },
                    { label: { title: "Medium" }, value: "Medium" },
                    { label: { title: "High" }, value: "High" },
                    { label: { title: "Critical" }, value: "Critical" },
                  ]}
                  label={""}
                  placeholder="Select Status"
                  setFieldValue={(_: any, value: string) => setStatus(value)}
                  values={{ status }}
                  setFieldTouched={undefined}
                  setFieldError={undefined}
                /> */}
              </div>
            )}
            renderColumns={() => (
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Region | District
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Contact
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  SPN | Account No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Geo Code | Meter No
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Address
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Service Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer Type
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Service Class
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Tarrif Class
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Status
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.AvatarShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 ">
                  <div className="flex items-center">
                    <div className="h-10 w-10 flex-shrink-0">
                      <Avatar
                        disabled={true}
                        alt={
                          (
                            item?.customer?.representative?.fullName ||
                            item?.customer?.organization?.name ||
                            item?.customer?.representative?.fullName
                          )?.trim() || "N A"
                        }
                        src={
                          item?.customer?.representative?.profileImageUrl ||
                          item?.customer?.representative?.profileImageUrl ||
                          ""
                        }
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 dark:text-gray-100">
                        {(
                          item?.customer?.representative?.fullName ||
                          item?.customer?.organization?.name ||
                          item?.customer?.representative?.fullName
                        )?.trim() || "N/A"}
                      </div>
                      <div className="text-gray-500 dark:text-gray-400">
                        {item?.customerCode || "N/A"}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.region?.name || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.district?.name || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.customer?.representative?.phoneNumber ||
                      item?.customer?.representative?.phoneNumber ||
                      "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.customer?.representative?.emailAddress ||
                      item?.customer?.representative?.emailAddress ||
                      "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.accountCode || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.geoCode || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.meterCode || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.ghanaPostAddress || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400 truncate max-w-md">
                    {item?.property?.address || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.serviceType || "N/A"}
                  </div>
                  {/* <div className='text-gray-500 dark:text-gray-400'>
                    {item?.tariffClass?.code || "N/A"}
                  </div> */}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.customerType || "N/A"}
                  </div>
                  {/* <div className='text-gray-500 dark:text-gray-400'>
                    {item?.tariffClass?.code || "N/A"}
                  </div> */}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.serviceClass === "Industrial" ? "SLT" : "NSLT"}
                  </div>
                  {/* <div className='text-gray-500 dark:text-gray-400'>
                    {item?.tariffClass?.code || "N/A"}
                  </div> */}
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.tariffClass?.name || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.tariffClass?.code || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <span
                    className={classNames(
                      "bg-gray-100 text-gray-800",
                      item?.status === "Diisconnected"
                        ? `bg-gray-100 text-[#F87A17] dark:bg-[#F87A17] dark:text-gray-50`
                        : "",
                      item?.status === "Finalized"
                        ? `bg-gray-100 text-[#FF4040] dark:bg-[#FF4040] dark:text-gray-50`
                        : "",
                      item?.status === "Pending"
                        ? `bg-gray-100 text-[#D4A017] dark:bg-[#D4A017] dark:text-gray-50`
                        : "",
                      item?.status === "Active"
                        ? `bg-green-100 text-green-800 dark:bg-green-600 dark:text-gray-50`
                        : "",
                      !item?.status
                        ? "bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-100"
                        : "",
                      "inline-flex rounded-full  px-2 py-1 text-xs items-center space-x-1"
                    )}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 10 10"
                      fill="currentColor"
                      className="w-1.5 h-1.5"
                    >
                      <circle
                        fillRule="evenodd"
                        cx="5"
                        cy="5"
                        r="5"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>{item?.status || "N/A"}</span>
                  </span>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewCustomerContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
        </>
      )}
    </>
  );
};

export default CustomersPage;
