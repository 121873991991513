import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";
import lodash from "lodash";

interface MeterSystemPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
  position?: "top" | "bottom";
}

const GET_REGIONS = gql`
  query GetMeterSystemsSelect {
    meterSystems: getMeterSystems(sort: "name", page: 0, pageSize: 0) {
      _id
      code
      name
    }
  }
`;

const MeterSystemPickerContainer: FC<MeterSystemPickerContainerProps> = ({
  id,
  label,
  rawId,
  ...form
}) => {
  const { loading, data } = useQuery(GET_REGIONS, {
    notifyOnNetworkStatusChange: false,
  });

  return (
    <SearchSelectInput
      id={id ?? "meterSystem"}
      label={label ?? "Meter System"}
      placeholder="Select Meter System"
      optionsLoading={loading}
      options={lodash
        .reject(
          data?.meterSystems,
          (tariffClass) => tariffClass?.name?.trim()?.length === 0
        )
        ?.map((meterSystem: any) => ({
          label: {
            title: meterSystem.name as string,
          },
          value: rawId ? meterSystem?._id : meterSystem,
        }))}
      {...form}
    />
  );
};

export default MeterSystemPickerContainer;
