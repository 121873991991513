import moment from "moment";

const Views = ["day", "week", "month", "quarter", "year", "custom"] as const;
type View = typeof Views[number];

const getPeriod = ({ view, fromDate, toDate }: { view?: View, fromDate?: string, toDate?: string }) => {
  const currentTime = moment().format("DD/MM/YYYY  hh:mm A");
  switch (view) {
    case "day": {
      return `${moment(fromDate).format("DD/MM/YYYY ")}, ${currentTime}`
    }
    case "week": {
      return `${moment(fromDate).format("[Week] W, YYYY")}, ${currentTime}`
    }
    case "month": {

      return `${moment(fromDate).format("MMMM, YYYY")}, ${currentTime}`
    }
    case "quarter": {
      return `${moment(fromDate).format("[Q]Q, YYYY")}, ${currentTime}`
    }
    case "year": {
      return `${moment(fromDate).format("YYYY")}, ${currentTime}`
    }
    case "custom": {
      return `${moment(fromDate).format("DD/MM/YYYY ")} to ${moment(toDate).format("DD/MM/YYYY ")}, ${currentTime}`
    }
  }
}

export default getPeriod