import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import useTableData from "./use-table-data";
import lodash from "lodash";
import { useMemo } from "react";
import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD";

interface UseSummaryData {
  rows: any[];
  count?: number;
}

const useSummaryData = (data: UseSummaryData): any => {
  const keyedGraphData = useMemo(
    () =>
      lodash
        .chain(data?.rows)
        .groupBy((datum) => moment(datum.date).format(DATE_FORMAT))
        .toPairs()
        .map(([date, values]) => {
          return [
            date,
            lodash.mergeWith({}, ...values, (objValue: any, srcValue: any) => {
              if (lodash.isNumber(objValue??0) && lodash.isNumber(srcValue??0)) {
                return (objValue??0) + (srcValue??0);
              }
            }),
          ];
        })
        .fromPairs()
        .value(),
    [data?.rows]
  );

  const groupedRecordsData = useMemo(
    () =>
      lodash
        .chain(data?.rows)
        .groupBy((datum) => `${datum.region?._id}${datum.district?._id}`)
        .toPairs()
        .map(([datum, values]: any) => {
          return {
            ...values?.[0],
            ...lodash.mergeWith(
              {},
              ...values,
              (objValue: any, srcValue: any) => {
                if (lodash.isNumber(objValue??0) && lodash.isNumber(srcValue??0)) {
                  return (objValue??0) + (srcValue??0);
                }
              }
            ),
          };
        })
        .value(),
    [data?.rows]
  );

  const totals = useMemo(
    () =>
      lodash.mergeWith(
        {},
        ...(data?.rows || []),
        (objValue: any, srcValue: any) => {
          if (lodash.isNumber(objValue??0) && lodash.isNumber(srcValue??0)) {
            return (objValue??0) + (srcValue??0);
          }
        }
      ),
    [data?.rows]
  );

  return {
    keyedGraphData,
    records: useTableData({ rows: groupedRecordsData }),
    totals,
  };
};

export default useSummaryData;

export const useUpdatedSummaryData = (data: UseSummaryData): any => {

  const totals = useMemo(
    () =>
      lodash.mergeWith(
        {},
        ...(data?.rows || []),
        (objValue: any, srcValue: any) => {
          if (lodash.isNumber(objValue??0) && lodash.isNumber(srcValue??0)) {
            return (objValue??0) + (srcValue??0);
          }
        }
      ),
    [data?.rows]
  );

  return {
    records: useTableData({ rows: lodash.sortBy(data?.rows, "label.name") }),
    totals,
  };
};

export const groupTariffClasses = (
  tariffClasses: { _id: string; code: string; name: string }[]
) => {
  return [
    ...lodash
      .chain(tariffClasses)
      .reject((tariffClass) => tariffClass?.code?.startsWith("N"))
      .sortBy(["code"])
      .value(),
    {
      _id: "Negotiated",
      code: "Negotiated",
      name: "Negotiated",
    },
  ];
};

export const groupMeterDetails = (
  meterDetails: { _id: string; code: string; name: string }[]
) => {
  return [
    ...lodash
      .chain(meterDetails)
      .reject((tariffClass) => tariffClass?.name?.trim()?.length === 0)
      .sortBy(["code"])
      .value(),
  ];
};

export const getTariffClassData = (
  data: { [key: string]: number },
  tariffClassCode: string
) => {
  if (tariffClassCode === "Negotiated") {
    return lodash
      .chain(data)
      .toPairs()
      .filter(([key]) => key.startsWith("N"))
      .sumBy(([key, value]) => value)
      .value();
  }
  return data?.[tariffClassCode] || 0;
};


export const getMeterData = (
  data: { [key: string]: number },
  meterCode: string
) => {
  if (meterCode === "Negotiated") {
    return lodash
      .chain(data)
      .toPairs()
      // .filter(([key]) => key.startsWith("N"))
      .sumBy(([key, value]) => value)
      .value();
  }
  return data?.[meterCode] || 0;
};
