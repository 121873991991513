import { FC, useMemo, useRef, useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import lodash from "lodash";
import { SearchMultiSelectInput, SearchSelectInput } from "components/core";
import moment from "moment";
import { classNames, wrapClick } from "utils";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { useReactToPrint } from "react-to-print";

const ViewTypes = ["Daily", "Weekly", "Monthly", "Quarterly"];
type ViewType = (typeof ViewTypes)[number];

const ViewFormats: Record<ViewType, string> = {
  Daily: "YYYY-MM-DD",
  Weekly: "[WK] WW, YYYY",
  Monthly: "MMM, YYYY",
  Quarterly: "[Q]Q, YYYY",
};

const groupByPeriod = (
  data: { day: string; value: Record<string, number> }[],
  keys: string[],
  period: ViewType = "Daily"
) => {
  const groupedData = lodash.groupBy(data, (datum) =>
    moment(datum.day, "YYYY-MM-DD").format(ViewFormats[period || "Daily"])
  );
  return lodash.toPairs(groupedData).map(([key, value]) => ({
    key,
    value: lodash
      .chain(keys)
      .map((keyShown) => [
        keyShown,
        lodash.sumBy(lodash.map(value, "value"), keyShown),
      ])
      .fromPairs()
      .value(),
  }));
};

interface LineChartProps {
  title: string;
  keys: any[];
  data: {
    value: Record<string, number>;
    day: string;
  }[];
  xLabel?: string;
  yLabel?: string;
  label: string;
  loading?: boolean;
  exportFileName: string;
}

const LineChart: FC<LineChartProps> = ({
  data,
  xLabel,
  yLabel,
  keys,
  title,
  label,
  exportFileName,
}) => {
  const ref = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: exportFileName || "Export",
    bodyClass: "w-[1480px] justify-center m-0.5",
  });
  const [keysShown, setKeysShown] = useState<string[]>([]);
  const [view, setView] = useState<ViewType>("Daily");

  const formattedData = useMemo(() => {
    const chunckedData = groupByPeriod(data, lodash.map(keys, "id"), view);

    return lodash
      .chain(keys)
      .thru((keysTap) => {
        if (keysShown.length > 0) {
          return keysTap.filter((key) => keysShown.includes(key.id));
        }
        return keysTap;
      })
      ?.map((key) => ({
        id: key.id,
        color: key.color,
        data: chunckedData.map((datum) => ({
          x: datum.key,
          y: lodash.get(datum.value, key.id) || 0,
        })),
      }))
      .value();
  }, [data, keysShown, keys, view]);

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <h3 className="text-md font-medium leading-6 text-gray-900">{title}</h3>
        <div className="flex space-x-3">
          <SearchSelectInput
            id="view"
            labelHidden={true}
            label=""
            placeholder="Select View Type"
            setFieldTouched={() => {}}
            setFieldError={() => {}}
            setFieldValue={(_field: string, value: ViewType) => {
              setView(value);
            }}
            values={{ view }}
            options={ViewTypes?.map((viewType) => ({
              label: { title: `${viewType} View` },
              value: viewType,
            }))}
          />
          {keys?.length > 1 && (
            <SearchMultiSelectInput
              id="keysShown"
              labelHidden={true}
              label=""
              placeholder={`Select ${label}`}
              setFieldTouched={() => {}}
              setFieldError={() => {}}
              setFieldValue={(_field: string, value: string[]) => {
                setKeysShown(value);
              }}
              values={{ keysShown }}
              options={keys?.map((key) => ({
                label: { title: key.label },
                value: key.id,
              }))}
            />
          )}

          <button
            type="button"
            onClick={wrapClick(handlePrint)}
            className=" inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            <CloudArrowDownIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
            Export To PDF
          </button>
        </div>
      </div>
      <div ref={ref} className="mt-3">
        <h3 className="hidden print:block py-3 text-center text-md font-bold text-gray-900 uppercase tracking-wider whitespace-nowrap">
          {exportFileName}
        </h3>
        <div className="overflow-hidden rounded-lg bg-white shadow print:shadow-none print:rounded-none print:border-gray-700 print:border">
          <div className="flex  xl:h-[30rem]">
            <ResponsiveLine
              data={formattedData}
              margin={{ top: 40, right: 50, bottom: 110, left: 60 }}
              xScale={{ type: "point" }}
              yScale={{
                type: "linear",
                min: 0,
                max: "auto",
                stacked: false,
                reverse: false,
              }}
              yFormat=" >-"
              curve="catmullRom"
              axisTop={null}
              axisRight={null}
              axisBottom={{
                // orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 90,
                legend: xLabel ?? "dates",
                legendOffset: 80,
                legendPosition: "middle",
              }}
              axisLeft={{
                // orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: yLabel ?? "counts",
                legendOffset: -40,
                legendPosition: "middle",
              }}
              enableArea={true}
              areaBaselineValue={10}
              enableGridY={true}
              enableGridX={true}
              pointSize={6}
              pointColor={{ theme: "background" }}
              pointBorderWidth={2}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              useMesh={true}
              colors={{
                datum: "color",
              }}
              enableSlices="x"
              sliceTooltip={({ slice }) => {
                return (
                  <div className="bg-white text-gray-700 text-sm shadow-lg rounded-sm p-3">
                    <div className="text-gray-900 mb-2">
                      {slice.points[0]?.data?.xFormatted}
                    </div>
                    {lodash.sortBy(slice.points, ["serieId"]).map((point) => (
                      <div
                        key={point.id}
                        className="flex flex-row justify-between items-center text-sm space-x-6"
                      >
                        <div className="text-gray-900 flex items-center justify-center">
                          <div
                            className="h-3 w-3 rounded-sm mr-2"
                            style={{ backgroundColor: point.serieColor }}
                          />{" "}
                          {point.serieId}:
                        </div>
                        <div>{point.data.yFormatted}</div>
                      </div>
                    ))}
                  </div>
                );
              }}
            />
          </div>
          {keys?.length > 1 && (
            <div className="flex space-x-6 px-6 pb-6 pt-3 justify-end">
              {keys?.map((key) => (
                <div
                  key={key.id}
                  className="rounded-lg bg-white p-2 flex space-x-2 items-center"
                >
                  <div
                    className={classNames(
                      key?.bgColor,
                      "rounded-md flex items-center justify-center p-2.5"
                    )}
                  />
                  <dt className="truncate text-xs text-gray-500">
                    {key?.label}
                  </dt>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LineChart;
