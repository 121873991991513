import { gql, useQuery } from "@apollo/client";
import { Disclosure } from "@headlessui/react";
import { FunnelIcon } from "@heroicons/react/20/solid";
import { useFormik } from "formik";
import lodash from "lodash";
import { FC, useEffect, useMemo } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  classNames,
  groupMeterDetails,
  groupTariffClasses,
  useUrlState,
  wrapClick,
} from "utils";

const GET_TARIFF_CLASSES_DATA = gql`
  query GetTariffClasses {
    rows: getTariffClasses(page: 0, pageSize: 0) {
      _id
      code
      name
    }
  }
`;

const GET_METER_SYSTEMS = gql`
  query GetMeterSystems {
    rows: getMeterSystems(page: 0, pageSize: 0) {
      _id
      code
      name
    }
  }
`;

const GET_METER_BRANDS = gql`
  query GetMeterBrands($systemCodes: [String]) {
    rows: getMeterBrands(systemCodes: $systemCodes page: 0, pageSize: 0) {
      _id
      code
      name
    }
  }
`;

const CustomerFilter: FC<{ filters?: string[] }> = ({
  filters: availableFilters,
}) => {

  const form = useFormik({
    initialValues: {
      categories: [],
      contractStatuses: [],
      serviceTypes: [],
      serviceClasses: [],
      customerTypes: [],
      tariffClasses: [],
      meterSystems: [],
      meterBrands: [],
    },
    onSubmit: (values) => {
      setFilter(values);
    },
    onReset: () => {
      setFilter(undefined);
    },
  });
  const [filter, setFilter] = useUrlState("filter");
  const { data: dataTariffClasses } = useQuery(GET_TARIFF_CLASSES_DATA);
  const { data: dataMeterSystems } = useQuery(GET_METER_SYSTEMS);
  const { data: dataMeterBrands } = useQuery(GET_METER_BRANDS, {
    variables: {
      ...(form?.values?.meterSystems?.length ? {systemCodes: form?.values?.meterSystems} : {}),
    },
  });
  const tariffClasses = useMemo(
    () => groupTariffClasses(dataTariffClasses?.rows),
    [dataTariffClasses?.rows]
  );
  const meterSystems = useMemo(
    () => groupMeterDetails(dataMeterSystems?.rows),
    [dataMeterSystems?.rows]
  );
  const meterBrands = useMemo(
    () => groupMeterDetails(dataMeterBrands?.rows),
    [dataMeterBrands?.rows]
  );

  const filters: {
    [key: string]: { value: string; label: string; checked: boolean }[];
  } = useMemo(
    () => ({
      categories: [
        { value: "Existing", label: "Existing", checked: false },
        { value: "NewService", label: "New Customers", checked: false },
        { value: "LateSetup", label: "Late Setup", checked: false },
      ],
      serviceTypes: [
        { value: "Prepaid", label: "Prepaid", checked: false },
        { value: "Postpaid", label: "Postpaid", checked: false },
        { value: "AMR", label: "AMR", checked: true },
      ],
      contractStatuses: [
        { value: "Active", label: "Active", checked: false },
        { value: "Suspended", label: "Suspended", checked: false },
        { value: "Terminated", label: "Terminated", checked: false },
      ],
      serviceClasses: [
        { value: "Residential", label: "Residential", checked: false },
        { value: "NonResidential", label: "Non Residential", checked: true },
        { value: "Industrial", label: "Industrial", checked: false },
      ],
      customerTypes: [
        { value: "Individual", label: "Individual", checked: false },
        { value: "Organization", label: "Organization", checked: false },
      ],
      tariffClasses: tariffClasses?.map((tariffClass) => ({
        value: tariffClass.code,
        label: `${tariffClass.name} (${tariffClass.code})`,
        checked: false,
      })),
      meterSystems: meterSystems?.map((meterSystem) => ({
        value: meterSystem.code,
        label: `${meterSystem.name} (${meterSystem.code})`,
        checked: false,
      })),
      ...(form?.values?.meterSystems?.length && meterBrands?.length ? {
      meterBrands: meterBrands?.map((meterBrand) => ({
        value: meterBrand.code,
        label: `${meterBrand.name} (${meterBrand.code})`,
        checked: false,
      }))
    } : {}),
    }),
    [tariffClasses, meterSystems, meterBrands, form?.values]
  );
  const filtersCount = useMemo(() => {
    if (!filter) {
      return 0;
    }
    return lodash.chain(filter).values().flatten().value().length;
  }, [filter]);

  const handleReset = () => {
    form.setValues({
      ...form.values,
      ...filter,
    })
  }

  return (
    <div className="bg-white flex-shrink-0">
      <Disclosure
        as="section"
        aria-labelledby="filter-heading"
        className="grid items-center border-b border-gray-200"
      >
      {({ close }) => (
        <>
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="relative col-start-1 row-start-1 py-4">
          <div className="mx-auto flex space-x-6 divide-x divide-gray-200 px-4 text-sm sm:px-6 lg:px-8">
            <div>
              <Disclosure.Button className="group flex items-center font-medium text-gray-700">
                <FunnelIcon
                  className="mr-2 h-5 w-5 flex-none text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
                {filtersCount > 0 ? filtersCount : "No"} Filters
              </Disclosure.Button>
            </div>
            <div className="pl-6">
              <button
                onClick={wrapClick(() => {form.resetForm(); close()})}
                type="button"
                className="text-gray-500"
              >
                Clear all
              </button>
            </div>
          </div>
        </div>
        <Disclosure.Panel className="border-t border-gray-200 py-10">
          <div className="mx-auto gap-x-4 px-4 text-sm sm:px-6 md:gap-x-6 lg:px-8 divide-y divide-gray-200">
            <div className="grid grid-cols-1 gap-y-10 md:grid-cols-6 md:gap-x-6 grid-flow-col-dense">
              {lodash
                .chain(filters)
                .keys()
                .intersection(availableFilters)
                ?.map((key) => (
                  <fieldset key={key} className={classNames(
                    filters[key]?.length > 5 ? "row-span-2" : "",
                    filters[key]?.length > 15 ? "col-span-2" : ""
                )}>
                    <legend className="block font-medium">
                      {lodash.startCase(key)}
                    </legend>
                    <div className={
                      classNames(
                        filters[key]?.length > 15 ? "grid-cols-2" : "grid-cols-1",
                        "grid gap-6 pt-6 sm:gap-4 sm:pt-4"
                      )}>
                      {filters[key].map((option, optionIdx) => (
                        <div
                          key={option.value}
                          className="flex items-center text-base sm:text-sm"
                        >
                          <input
                            id={`${option?.value}.${key}`}
                            aria-describedby={`${option.value}-description`}
                            name={`${option?.value}.${key}`}
                            type="checkbox"
                            value={option.value}
                            checked={lodash.includes(
                              (form?.values as any)?.[key],
                              option.value
                            )}
                            onChange={(e) =>
                              e.target.checked
                                ? form.setFieldValue(key, [
                                    ...(form?.values as any)?.[key],
                                    option.value,
                                  ])
                                : form.setFieldValue(
                                    key,
                                    lodash.filter(
                                      (form?.values as any)?.[key],
                                      (perm) => perm !== option.value
                                    )
                                  )
                            }
                            className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
                          />
                          <label
                            htmlFor={`${option?.value}.${key}`}
                            className="ml-3 min-w-0 flex-1 text-gray-600"
                          >
                            {option.label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </fieldset>
                ))
                .value()}
            </div>
            <div className="py-3 mt-5 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={wrapClick(() => {form.handleSubmit(); close()})}
              >
                Update Filters
              </button>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={(e) => {handleReset(); close()}}
              >
                Cancel
              </button>
            </div>
          </div>
        </Disclosure.Panel>
        </>
        )}
        </Disclosure>
    </div>
  );
};

export default CustomerFilter;
