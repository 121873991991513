import { Shimmers } from "components/tables";
import { FC } from "react";
import lodash from "lodash";
import TableBodyComponent from "components/tables/table-body";

interface StatsPageLoaderProps { }

const StatsPageLoader: FC<StatsPageLoaderProps> = () => {
  return (
    <div className="flex-1 min-w-0  py-4 sm:py-6 px-6 overflow-hidden overflow-y-auto light flex flex-col">
      <div className="">
        <div className="flex flex-col">
          <h3
            style={{ width: `${lodash.random(120, 180, false)}px` }}
            className="text-md font-medium leading-6 text-gray-900"
          >
            <Shimmers.SingleShimmer />
          </h3>
          <div className="mt-3 ">
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-6">
              {lodash.times(6).map((item) => (
                <div
                  key={item}
                  className="relative overflow-hidden rounded-lg bg-white px-4 py-1 shadow"
                >
                  <dt>
                    <div
                      style={{ width: `${lodash.random(120, 180, false)}px` }}
                    >
                      <Shimmers.SingleShimmer />
                    </div>
                  </dt>
                  <dd className="flex justify-between items-end">
                    <div
                      style={{ width: `${lodash.random(120, 180, false)}px` }}
                    >
                      <Shimmers.SingleShimmer />
                    </div>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-6 space-x-6">
        <div className="flex-1">
          <div className="flex flex-col">
            <h3 className="text-md font-medium leading-6 text-gray-900 w-64">
              <Shimmers.SingleShimmer />
            </h3>
            <div className="mt-3 overflow-y-auto rounded-lg bg-white shadow">
              <TableBodyComponent
                noSpace={true}
                loading={false}
                data={{
                  rows: lodash.times(5),
                }}
                renderColumns={() => (
                  <tr>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-4 text-left text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-4 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-4 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-4 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="sticky top-0 border-y border-gray-200 bg-gray-50 dark:bg-gray-800 px-4 text-right text-xs font-light text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                    >
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </th>
                  </tr>
                )}
                renderItem={() => (
                  <tr className="border-t border-gray-200">
                    <td className="px-4 py-4 border-b border-gray-200">
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200">
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200">
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200">
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200">
                      <div
                        style={{
                          width: `${lodash.random(120, 180, false)}px`,
                        }}
                      >
                        <Shimmers.SingleShimmer />
                      </div>
                    </td>
                  </tr>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div>
          <div className="flex justify-between items-center">
            <h3 className="text-md font-medium leading-6 text-gray-900 w-64">
              <Shimmers.SingleShimmer />
            </h3>
            <div className="flex space-x-3">
              <div style={{ width: `${lodash.random(120, 180, false)}px` }}>
                <Shimmers.SingleShimmer />
              </div>
              <div style={{ width: `${lodash.random(120, 180, false)}px` }}>
                <Shimmers.SingleShimmer />
              </div>
            </div>
          </div>
          <div className="mt-3 overflow-hidden rounded-lg bg-white shadow">
            <div className="flex  xl:h-[24rem] items-center p-12 animate-pulse">
              <svg
                id="wave"
                style={{ transform: "rotate(0deg)", transition: "0.3s" }}
                viewBox="0 0 1440 280"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient
                    id="sw-gradient-0"
                    x1="0"
                    x2="0"
                    y1="1"
                    y2="0"
                  >
                    <stop
                      stop-color="rgba(156, 163, 175, 1)"
                      offset="0%"
                    ></stop>
                    <stop
                      stop-color="rgba(209, 213, 219, 1)"
                      offset="100%"
                    ></stop>
                  </linearGradient>
                </defs>
                <path
                  style={{ transform: "translate(0, 0px)", opacity: 1 }}
                  stroke="url(#sw-gradient-0)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={8}
                  fill="none"
                  d="M10,238C20,204,40,136,60,102C80,68,100,68,120,73.7C140,79,160,91,180,102C200,113,220,125,240,147.3C260,170,280,204,300,215.3C320,227,340,215,360,209.7C380,204,400,204,420,198.3C440,193,460,181,480,187C500,193,520,215,540,209.7C560,204,580,170,600,170C620,170,640,204,660,198.3C680,193,700,147,720,141.7C740,136,760,170,780,164.3C800,159,820,113,840,96.3C860,79,880,91,900,113.3C920,136,940,170,960,175.7C980,181,1000,159,1020,175.7C1040,193,1060,249,1080,243.7C1100,238,1120,170,1140,130.3C1160,91,1180,79,1200,107.7C1220,136,1240,204,1260,215.3C1280,227,1300,181,1320,170C1340,159,1360,181,1380,204C1400,227,1420,249,1430,260.7"
                ></path>
              </svg>
            </div>
            <div className="flex space-x-6 px-6 pb-3 pt-3 justify-end">
              {lodash?.times(3)?.map((key) => (
                <div
                  key={key}
                  className="rounded-lg bg-white p-2 flex space-x-2 items-center"
                >
                  <div style={{ width: `${lodash.random(120, 140, false)}px` }}>
                    <Shimmers.SingleShimmer />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsPageLoader;
