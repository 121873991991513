import { gql, useLazyQuery, useReactiveVar } from "@apollo/client";
import { OfficeHeader, Shimmers, TableComponent } from "components";
import moment from "moment";
import { FC, useEffect, useMemo } from "react";
import { useNavigate, useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { wrapClick, useUrlState, useTableData, useDownloadFile, getPeriod } from "utils";
import config from "config";
import ViewInstallationServiceOrderContainer from "./view";
import { currentConfigVar } from "apollo/cache/config";
import { Action } from "components/buttons/action-button";
import lodash from "lodash";
import { useInstallationServiceOrders } from "./hooks";
import numeral from "numeral";
import toast from "react-hot-toast";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
const DATE_FORMAT = "YYYY-MM-DD";

export const GET_SERVICE_ORDER_EXPORT_URL = gql`
  query Query(
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: InstallationServiceOrderStatus
    $statuses: [InstallationServiceOrderStatus]
    $fromDate: Date
    $toDate: Date
    $dateField: String
    $fileName: String
  ) {
    url: getHassleFreeExportUrl(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      statuses: $statuses
      fromDate: $fromDate
      toDate: $toDate
      dateField: $dateField
      fileName: $fileName
    )
  }
`;

const InstallationServiceOrdersPage: FC = () => {
  const { dateFormat } = useReactiveVar(currentConfigVar);
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");
  const { downloadAction, downloadLoading } = useDownloadFile({
    onError: () => {
      toast(
        JSON.stringify({
          type: "error",
          title: "An error occurred downloading file",
        })
      );
    },
    getFileName: () => new Date().toISOString() + "_HasleFree.xlsx",
  });
  const handleDownloadItem = () => {
    createDataExport({
      variables: filter,
      fetchPolicy: "no-cache",
    }).then(({ data }) => {
      if (data?.url) {
        downloadAction(`${data.url.replace(/_/g, `:`)}`);
      } else {
        toast(
          JSON.stringify({ type: "error", title: "Could not export file" })
        );
      }
    }).catch(err => {
      toast(
        JSON.stringify({ type: "error", title: err?.message })
      );
      console.error(err)
    });
  };

  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  const revokeAction =
    (action: Exclude<Action, "expand" | "goto" | "clone">, val: boolean) =>
      () => {
        // alert(JSON.stringify({ action, val }))
        setModal(val ? action : undefined);
        navigate({
          search: (old) => ({
            ...old,
            id: val ? old?.id : undefined,
          }),
        });
      };

  const filter = useMemo(
    () => ({
      page: searchParams.page || 1,
      pageSize: searchParams.pageSize || 12,
      search: (searchParams.search || "").toString(),
      searchFields: [
        "code",
        "serviceRequestCode",
        "servicePointCode",
        "contactPerson.fullName",
        "contactPerson.phoneNumber",
        "location.address",
        "location.community",
      ],
      sort: searchParams.sort || "completedAt",
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      status: "Completed",
      dateField: "completedAt",
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
      fileName: `Hassle Free Report For ${getPeriod(
        searchParams
      )}`
    }),
    [searchParams]
  );

  const { installationServiceOrders, count, loading, networkStatus, refetch } =
    useInstallationServiceOrders(filter);

  const records = useTableData({ rows: installationServiceOrders, count });

  const [createDataExport, { loading: gettingUrl }] = useLazyQuery(
    GET_SERVICE_ORDER_EXPORT_URL,
    {
      fetchPolicy: "no-cache",
    }
  );
  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "completedAt" } : {}),
      }),
    });
  }, [navigate]);

  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-gray-50">
      <OfficeHeader />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
          <TableComponent
            title={"installations"}
            refetch={refetch}
            isRefetching={loading && networkStatus === 4}
            loading={loading && ![4, 6].includes(networkStatus)}
            data={records}
            hasSearch={true}
            renderHeaderItems={() => (
              <div className="mt-4 sm:mt-0.5 sm:ml-16 sm:flex space-x-2">
                <button
                  type="button"
                  onClick={wrapClick(handleDownloadItem)}
                  disabled={gettingUrl || downloadLoading}
                  className="ml-3 inline-flex items-center rounded-md border border-transparent bg-primary-600 px-3 py-2 text-sm leading-4 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                >
                  <CloudArrowDownIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {gettingUrl
                    ? "Processing file for download"
                    : downloadLoading
                      ? "Exporting"
                      : "Export To Excel"}
                </button>
              </div>
            )}
            renderColumns={() => (
              <tr>
                {!searchParams.district && (
                  <th
                    scope="col"
                    className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                  >
                    {!searchParams.region ? "Region | " : ""}District
                  </th>
                )}
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Customer Name
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Request Number | SPN
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Request Type | Category
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Payment Date | Amount
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Installation Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Resolution Date
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Number Of Days
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Tariff
                </th>
                <th
                  scope="col"
                  className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-6 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                >
                  Meter
                </th>
              </tr>
            )}
            renderLoader={() => (
              <tr>
                {!searchParams.district && (
                  <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                    <Shimmers.SingleShimmer />
                  </td>
                )}
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.SingleShimmer />
                </td>
                <td className="px-6 py-4 border-b border-gray-200 print:border-gray-700">
                  <Shimmers.DoubleShimmer />
                </td>
              </tr>
            )}
            renderItem={(item) => (
              <tr
                key={item._id}
                className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                onClick={wrapClick(dispatchAction(item._id, "view"))}
              >
                {!searchParams.district && (
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 ">
                    {!searchParams.region && (
                      <div className="text-gray-900 dark:text-gray-100">
                        {item?.region?.name || "N/A"}
                      </div>
                    )}
                    <div className="text-gray-500 dark:text-gray-500">
                      {item?.district?.name || "N/A"}
                    </div>
                  </td>
                )}
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.contactPerson?.fullName || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.request?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.servicePoint?.code || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700">
                  <div className="text-gray-900 dark:text-gray-100">
                    {lodash.startCase(item?.request?.type) || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-500">
                    {item?.request?.category || "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-right">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.request?.connectionFee?.paidAt
                      ? moment(item?.request?.connectionFee?.paidAt).format(
                        dateFormat
                      )
                      : "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    GHS{" "}
                    {numeral(item?.request?.connectionFee?.amount).format(
                      "0,0.00"
                    )}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-right">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.resolution?.reading?.readingDate
                      ? moment(item?.resolution?.reading?.readingDate).format(
                        dateFormat
                      )
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-right">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.completedAt
                      ? moment(item?.completedAt).format(dateFormat)
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-right">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.resolution?.reading?.readingDate
                      ? `${numeral(
                        lodash.ceil(
                          moment(item?.resolution?.reading?.readingDate).diff(
                            moment(item?.request?.connectionFee?.paidAt),
                            "days",
                            true
                          )
                        )
                      ).format("0,0")}`
                      : "N/A"}
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-right">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.servicePoint?.tariffClass?.code || "N/A"} (
                    {item?.servicePoint?.tariffClass?.name || "N/A"})
                  </div>
                </td>
                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-700 border-b border-gray-200 print:border-gray-700 text-left">
                  <div className="text-gray-900 dark:text-gray-100">
                    {item?.installedMeter?.code || "N/A"}
                  </div>
                  <div className="text-gray-500 dark:text-gray-400">
                    {item?.installedMeter?.modelMeta?.phase || "N/A"}
                  </div>
                </td>
              </tr>
            )}
          />
        </div>
      </div>

      {!!searchParams.id?.length && (
        <>
          <ViewInstallationServiceOrderContainer
            open={modal === "view"}
            setOpen={(val: boolean) => revokeAction("view", val)()}
          />
        </>
      )}
    </main>
  );
};

export default InstallationServiceOrdersPage;
