import { FC } from "react";
import { IResolveInspectionServiceOrderFormSchema } from "./schema";
import numeral from "numeral";
import { wrapImage } from "utils";

const MaterialInfoSummary: FC<{ data: IResolveInspectionServiceOrderFormSchema["extraInfo"] }> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className=''>
      <span className="text-xs font-light">Reciepts Information</span>
      <div className='grid grid-cols-2 gap-6 mt-2'>
      <div>
        <span className="text-xs font-light">Bill Of Lading</span>
        {data?.billOfLadingDocumentUrl ? (
          <div className="mt-2">
            {wrapImage(
              <img
                src={data?.billOfLadingDocumentUrl}
                alt={""}
                className="w-full h-64 text-xs"
              />
            )}
          </div>
        ) : (
          <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
            Bill Of Lading not provided
          </div>
        )}
      </div>
      <div>
        <span className="text-xs font-light"> Customer Supplied Materials Receipt</span>
        {data?.customerMaterialsReceiptDocumentUrl ? (
          <div className="mt-2">
            {wrapImage(
              <img
                src={data?.customerMaterialsReceiptDocumentUrl}
                alt={""}
                className="w-full h-64 text-xs"
              />
            )}
          </div>
        ) : (
          <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
            Customer Supplied Materials Receipt not provided
          </div>
        )}
      </div>
      </div>
    </div>
    <div className='pt-6'>
      <span className="text-xs font-light">Materials Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div className="col-start-1 col-span-3">
          <span className="block text-sm font-light text-gray-700">
            Customer Supplied Materials
          </span>
          <div className="mt-1 w-full ">
            <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
              <thead className="bg-gray-50">
                <tr className="divide-x divide-gray-200">
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[55%]"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[15%]  text-right"
                  >
                    Unit Price
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[15%]  text-right"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[15%] text-right"
                  >
                    Price
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data?.customerMaterials?.map((appliance, idx) => (
                  <tr key={idx} className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{appliance?.material || "N/A"}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(appliance?.unitCost || 0).format("0,0.00")}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(appliance?.quantity || 0).format("0,0")}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral((appliance?.unitCost || 0) * (appliance?.quantity || 0)).format("0,0.00")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-start-1 col-span-3">
          <span className="block text-sm font-light text-gray-700">
            ECG Supplied Materials
          </span>
          <div className="mt-1 w-full ">
            <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
              <thead className="bg-gray-50">
                <tr className="divide-x divide-gray-200">
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[55%]"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[15%]  text-right"
                  >
                    Unit Price
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[15%]  text-right"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[15%] text-right"
                  >
                    Price
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data?.installationMaterials?.map((appliance, idx) => (
                  <tr key={idx} className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{appliance?.material || "N/A"}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(appliance?.unitCost || 0).format("0,0.00")}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(appliance?.quantity || 0).format("0,0")}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral((appliance?.unitCost || 0) * (appliance?.quantity || 0)).format("0,0.00")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-start-1 col-span-3">
          <span className="block text-sm font-light text-gray-700">
            Supplementary Charges
          </span>
          <div className="mt-1 w-full ">
            <table className="min-w-full divide-y divide-gray-300 border border-gray-300 rounded-md">
              <thead className="bg-gray-50">
                <tr className="divide-x divide-gray-200">
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-left text-sm font-semibold text-gray-900 w-[55%]"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[15%]  text-right"
                  >
                    Unit Price
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[15%]  text-right"
                  >
                    Quantity
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-2 text-sm font-semibold text-gray-900 w-[15%] text-right"
                  >
                    Price
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {data?.installationCharges?.map((appliance, idx) => (
                  <tr key={idx} className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-700">{appliance?.title || "N/A"}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(appliance?.unitCost || 0).format("0,0.00")}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral(appliance?.quantity || 0).format("0,0")}</td>
                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 text-right">{numeral((appliance?.unitCost || 0) * (appliance?.quantity || 0)).format("0,0.00")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6">
      <span className="text-xs font-light">Extra Information</span>
      <div className="grid gap-6 mt-2">
        <div>
          <span className="block text-sm font-light text-gray-700">
            Notes
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.notes || "N/A"}
          </div>
        </div>

      </div>
    </div>
  </div>
)

export default MaterialInfoSummary;