import { gql, useQuery } from "@apollo/client";
import { LineChart, Shimmers, StatsPageLoader } from "components";
import { FC, useMemo } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import {
  getPeriod,
  useSummaryData,
} from "utils";
import numeral from "numeral";
import lodash from "lodash";
import moment from "moment";
import TableBodyComponent from "components/tables/table-body";
const DATE_FORMAT = "YYYY-MM-DD";

const SUMMARY = gql`
  query GetSummary($fromDate: Date, $toDate: Date, $region: ID, $district: ID) {
    rows: getSummary(
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
    ) {
      _id
      date
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      newCustomers
      newCustomersIndividual
      newCustomersOrganization
      newCustomersPostpaid
      newCustomersPrepaid
      newCustomersAMR
      newCustomersResidential
      newCustomersNonResidential
      newCustomersIndustrial
      newCustomersStandard
      newCustomersExpress
      newCustomersPremium
      newCustomersTariffClasses
      lateSetups
      lateSetupsIndividual
      lateSetupsOrganization
      lateSetupsPostpaid
      lateSetupsPrepaid
      lateSetupsAMR
      lateSetupsResidential
      lateSetupsNonResidential
      lateSetupsIndustrial
      lateSetupsShep
      lateSetupsNotMigrated
      lateSetupsRegularization
      lateSetupsTariffClasses
      replacementsPostpaidToPrepaid
      replacementsPrepaidToPrepaid
      replacementsPostpaidToPostpaid
      replacementsPrepaidToPostpaid
      replacementsPostpaidToAMR
      replacementsPrepaidToAMR
      replacementsAMRToAMR
      replacementsAMRToPrepaid
      replacementsAMRToPostpaid
      complaintsNew
      complaintsResolved
      complaintsInProgress
      complaintsCancelled
      newReadings
      newReadingExceptions
      newReadingAnomalies
      newMeterEngagements
      newNoTraces
      newNoAccesses
      newUnlistedMeters
      consumptionBilled
      amountBilled
    }
  }
`;

const CustomerComplaintsSummaryPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();

  const filter = useMemo(
    () => ({
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
    }),
    [searchParams]
  );

  const { data, loading, networkStatus } = useQuery(SUMMARY, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  const { keyedGraphData, records, totals } = useSummaryData(data);

  const stats = useMemo(
    () => [
      {
        id: "new",
        name: "New",
        value: lodash.get(totals, "complaintsNew"),
      },
      {
        id: "resolved",
        name: "Resolved",
        value: lodash.get(totals, "complaintsResolved"),
      },
      {
        id: "cancelled",
        name: "Cancelled",
        value: lodash.get(totals, "complaintsCancelled"),
      },
      {
        id: "inProgress",
        name: "In Progress",
        value: lodash.get(totals, "complaintsInProgress"),
      },
    ],
    [totals]
  );

  if(loading) {
    return <StatsPageLoader />
  }

  return (
    <div className="flex-1 min-h-full min-w-0  py-4 sm:py-6 px-6 overflow-hidden overflow-y-auto light flex flex-col">
      <div className="">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Summary
          </h3>
          <div className="mt-3 ">
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white p-4 shadow"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="flex justify-between items-end">
                    <p className="text-xl font-light print:font-bold  text-gray-900">
                      {numeral(item.value).format("0,0")}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      {!searchParams.district && (
        <div className="flex flex-row mt-6 space-x-6">
          <div className="flex-1">
            <div className="flex flex-col">
              <div className="overflow-y-auto">
                <TableBodyComponent
                title={`Complaints Per Status Per ${
                  searchParams?.region ? "District" : "Region"
                }`}
                exportFileName={`Complaints By Status  Per ${
                  searchParams?.region ? "District" : "Region"
                } For ${getPeriod(searchParams)}`}
                exportTypes={["EXCEL", "PDF"]}
                  noSpace={true}
                  loading={loading && ![4, 6].includes(networkStatus)}
                data={{
                  ...records,
                  rows: [
                    ...records.rows,
                    {
                      ...totals,
                      _id: "total",
                      region: {
                        _id: "total",
                        code: "total",
                        name: "Total",
                      },
                      district: {
                        _id: "total",
                        code: "total",
                        name: "Total",
                      },
                    },
                  ],
                }}
                  renderColumns={() => (
                    <>
                      <tr className="hidden print:table-row">
                        <th
                          scope="col"
                          colSpan={2}
                          className="sticky top-0 border-t border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-center text-md font-bold text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Complaints By Status Per{" "}
                          {searchParams?.region ? "District" : "Region"} For{" "}
                          {getPeriod(searchParams)}
                        </th>
                      </tr>
                    <tr>
                      {!!searchParams.region ? (
                        <th
                          scope="col"
                          className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          District
                        </th>
                      ) : (
                        <th
                          scope="col"
                          className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Region
                        </th>
                      )}
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        New
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Resolved
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Cancelled
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        In Progress
                      </th>
                    </tr>
                    </>
                  )}
                  renderLoader={() => (
                    <tr className="border-t border-gray-200 print:border-gray-700">
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                    </tr>
                  )}
                  renderItem={(item) => (
                    <tr
                      key={item._id}
                      className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                    >
                      {!!searchParams.region ? (
                        <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                          {item?.district?.name || "N/A"}
                        </td>
                      ) : (
                        <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                          {item?.region?.name || "N/A"}
                        </td>
                      )}
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.complaintsNew).format("0,0") || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.complaintsResolved).format("0,0") || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.complaintsCancelled).format("0,0") || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.complaintsInProgress).format("0,0") || "N/A"}
                      </td>
                    </tr>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-6">
        <LineChart
          title="Complaints Per Status Per Day"
          exportFileName={`Complaints Per Status For ${getPeriod(searchParams)}`}
          label="Status"
          loading={loading && ![4, 6].includes(networkStatus)}
          keys={[
            {
              color: "#2563eb",
              bgColor: "bg-blue-600",
              id: "New",
              label: "New",
            },
            {
              color: "#0d9488",
              bgColor: "bg-teal-600",
              id: "Resolved",
              label: "Resolved",
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "InProgress",
              label: "In Progress",
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "Cancelled",
              label: "Cancelled",
            },
          ]}
          data={lodash.times(
            moment(searchParams.toDate, DATE_FORMAT).diff(
              moment(searchParams.fromDate, DATE_FORMAT),
              "days"
            ) + 1,
            (idx) => {
              const currentDate = moment(searchParams.fromDate)
                .add(idx, "days")
                .format(DATE_FORMAT);
              return {
                value: {
                  New: lodash.get(
                    keyedGraphData,
                    `${currentDate}.complaintsNew`,
                    0
                  ),
                  Resolved: lodash.get(
                    keyedGraphData,
                    `${currentDate}.complaintsResolved`,
                    0
                  ),
                  InProgress: lodash.get(
                    keyedGraphData,
                    `${currentDate}.complaintsInProgress`,
                    0
                  ),
                  Cancelled: lodash.get(
                    keyedGraphData,
                    `${currentDate}.complaintsCancelled`,
                    0
                  ),
                },
                day: currentDate,
              };
            }
          )}
        />
      </div>
    </div>
  );
};

export default CustomerComplaintsSummaryPage;
