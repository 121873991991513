import { gql, useQuery } from '@apollo/client'
import { FC } from 'react';
import { SearchSelectInput } from 'components/core';
import lodash from 'lodash';

interface TariffClassPickerContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
  position?: ("top" | "bottom")
}

const GET_REGIONS = gql`
  query GetTariffClassesSelect {
    tariffClasses: getTariffClasses(sort: "name") {
      _id
      code
      name
      serviceClass
    }
  }
`;

const TariffClassPickerContainer: FC<TariffClassPickerContainerProps> = ({ id, label, rawId, ...form }) => {
  const { loading, data } = useQuery(GET_REGIONS, {
    notifyOnNetworkStatusChange: false
  })

  return (
    <SearchSelectInput
      id={id ?? "tariffClass"}
      label={label ?? "Tariff Class"}
      placeholder="Select Tariff Class"
      optionsLoading={loading}
      options={(data?.tariffClasses ?? [])?.filter((tariffClass: any) =>  ["Industrial", "SpecialLoadTariff"].includes(tariffClass.serviceClass))?.map((tariffClass: any) => ({
        label: {
          title: tariffClass.name as string,
        },
        value: rawId ? tariffClass?._id : lodash.omit(tariffClass, ["serviceClass"])
      }))}
      {...form}
    />
  )
}

export default TariffClassPickerContainer