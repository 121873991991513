import { gql } from "@apollo/client";

export const GET_REPLACEMENT_SERVICE_ORDERS = gql`
  query GetReplacementServiceOrders(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: ReplacementServiceOrderStatus
    $priority: ReplacementServiceOrderPriority
    $assigner: ID
    $approver: ID
    $assignee: ID
    $farmingOutContractor: ID
  ) {
    rows: getReplacementServiceOrders(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      priority: $priority
      assigner: $assigner
      approver: $approver
      assignee: $assignee
      farmingOutContractor: $farmingOutContractor
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      location {
        address
        community
      }
      meterContractor {
        _id
        code
        name
      }
      farmingOutContractor {
        _id
        code
        name
      }
      assigneeType
      assigner {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      approver {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      assignee {
        _id
        code
        lastName
        firstName
        ghanaCardNumber
        phoneNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
        }
      }
      status
      priority
      estimatedResolutionDate
      servicePoint {
        _id
        code
        geoCode
      }
      servicePointCode
      replacementType
      meter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
      }
      installedMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
        }
      }
      installationMeterSystemCode
      installationMeterSystemSyncStatus
      assignedAt
      startedAt
      resolvedAt
      completedAt
      rejectedAt
      createdAt
      updatedAt
      oldMeterNumber
      replacementReason
    }
    count: getReplacementServiceOrdersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      priority: $priority
    )
  }
`;

export const GET_REPLACEMENT_SERVICE_ORDER_INITIATIONS = gql`
  query GetReplacementServiceOrderInitiations(
    $page: Int
    $pageSize: Int
    $search: String
    $searchFields: [String!]
    $sort: String
    $region: ID
    $district: ID
    $status: ReplacementServiceOrderInitiationStatus
  ) {
    rows: getReplacementServiceOrderInitiations(
      page: $page
      pageSize: $pageSize
      search: $search
      searchFields: $searchFields
      sort: $sort
      region: $region
      district: $district
      status: $status
    ) {
      _id
      code
      status
      createdAt
      updatedAt
      initiations {
        _id
        servicePointCode
        estimatedResolutionDate
        replacementReason
        debtAmount
        creditAmount
        phoneNumber
        ghanaPostAddress
        status
        statusReason
      }
      contractor {
        _id
        code
        name
      }
      region {
        _id
        code
        name
      }
      meta {
        totalFailedInitiations
        totalInitiations
        totalSuccessInitiations
      }
      createdBy {
        _id
        code
        lastName
        firstName
        emailAddress
        phoneNumber
      }
      district {
        _id
        code
        name
      }
    }
    count: getReplacementServiceOrderInitiationsCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
    )
  }
`;
