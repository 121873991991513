import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";



interface UseTableData {
    rows: any[];
    count?: number;
}

const useTableData = (data: UseTableData): any => {
    const searchParams = useSearch<LocationGenerics>();
    return {
        rows: data?.rows || [],
        total: data?.count || 0,
        totalPages: Math.ceil((data?.count || 0) / (searchParams?.pageSize ?? 10)),
        page: searchParams.page || 1,
        pageSize: searchParams.pageSize || 10,
    }
}

export default useTableData;