import { FC } from "react";
import { Link } from "react-location";
import {
  CursorArrowRaysIcon,
  EnvelopeOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { useReactiveVar } from "@apollo/client";
import { currentUserVar } from "apollo/cache/auth";
import { OfficeHeader } from "components";

const stats = [
  { id: "customers?tab=summary", name: "Customers Summary", icon: UsersIcon },
  {
    id: "customers?tab=statuses",
    name: "Customers By Status",
    icon: UsersIcon,
  },
  {
    id: "customers?tab=summary",
    name: "Customers By Categories",
    icon: EnvelopeOpenIcon,
  },
  {
    id: "customers?tab=summary",
    name: "Customers By Service Classes",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "customers?tab=service-types",
    name: "Customers By Service Types",
    icon: EnvelopeOpenIcon,
  },
  {
    id: "customers?tab=tariff-classes",
    name: "Customers By Tariff Classes",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "customers?tab=customer-types",
    name: "Customers By Customer Types",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "customers?tab=meter-types",
    name: "Customers By Meter Types",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "customers?tab=customer-data",
    name: "Customers Data",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "replacements?tab=summary",
    name: "Replacements Summary",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "replacements?tab=replacement-reasons",
    name: "Replacements By Reasons",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "replacements?tab=replacement-types",
    name: "Replacements By Types",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "replacements?tab=replacements-data",
    name: "Replacements Data",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "pending-inspections",
    name: "Pending Inspections",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "pending-installations",
    name: "Pending Installations",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "contracting-ageing",
    name: "Hassle Free Report",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "customer-complaints?tab=summary",
    name: "Customer Complaints Summary",
    icon: CursorArrowRaysIcon,
  },
  {
    id: "replacements?tab=complaints-data",
    name: "Customer Complaints Data",
    icon: CursorArrowRaysIcon,
  },
];

const DashboardPage: FC = () => {
  const currentUser = useReactiveVar(currentUserVar);

  return (
    <div className="h-full flex flex-1 flex-col overflow-hidden ">
      <OfficeHeader />
      <div className="flex-1 w-full flex overflow-hidden">
        <div className="flex-1 min-h-full mx-auto  p-4 sm:p-6 overflow-y-auto bg-gray-50">
          <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-12 lg:gap-8">
            <div className="col-span-12">
              <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 grid-flow-row-dense">
                <div className="row-span-2 relative isolate overflow-hidden rounded-lg bg-primary-400 px-4 pb-12 pt-5 shadow sm:px-6 sm:pt-6">
                  <div className="absolute inset-y-0 right-8 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-15deg] bg-primary-500 shadow-xl shadow-primary-400 ring-1 ring-primary-400 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                  <div className="absolute inset-y-0 right-24 -z-10 mr-16 w-[200%] origin-bottom-left skew-x-[-20deg] bg-primary-600 shadow-xl shadow-primary-500 ring-1 ring-primary-500 sm:mr-28 lg:mr-0 xl:mr-16 xl:origin-center" />
                  <div className="absolute inset-0 -z-10 bg-gradient-to-b from-transparent via-primary-600/80 to-primary-600" />
                  <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <span className="text-white text-xl font-extralight">
                      Welcome back{" "}
                      <span className="font-semibold">
                        {currentUser?.firstName}
                      </span>
                    </span>
                  </div>
                </div>
                {stats.map((item, idx) => (
                  <div
                    key={idx}
                    className="flex flex-col overflow-hidden rounded-lg bg-white shadow"
                  >
                    <p className="text-md font-light print:font-bold  text-gray-900 p-4 sm:px-6 flex-1">
                      {item.name}
                    </p>
                    <div className="bg-gray-50 p-4 sm:px-6">
                      <div className="text-sm">
                        <Link
                          to={`/${item.id}`}
                          className="font-medium text-primary-600 hover:text-primary-500"
                        >
                          View Report &rarr;
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
