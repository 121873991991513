import { gql, useQuery } from "@apollo/client";
import { FC } from "react";
import { SearchSelectInput } from "components/core";
import { PremiseCategory } from "apollo/data";
import _ from "lodash";

interface SubActivityPickerContainerProps {
  filter?: {
    activity?: string;
  };
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  labelHidden?: boolean;
  values: any;
  errors?: any;
  touched?: any;
  setFieldValue: any;
  setFieldTouched: any;
  setFieldError: any;
  rawId?: boolean;
}

const GET_SUB_ACTIVITIES = gql`
  query GetSubActivitiesSelect($activity: ID) {
    subActivities: getSubActivities(activity: $activity sort: "name") {
      _id
      code
      name
    }
  }
`;

const SubActivityPickerContainer: FC<SubActivityPickerContainerProps> = ({
  filter,
  id,
  label,
  rawId,
  ...form
}) => {
  const items = PremiseCategory?.filter((el) => _.has(el, "activity"))
    ?.filter(
      (el) => el?.type === "SubActivity" && el?.activity === filter?.activity
    )
    ?.map((subActivity: any) => ({
      label: {
        title: subActivity.name as string,
      },
      value: rawId ? subActivity._id : subActivity,
    }));

  return (
    <SearchSelectInput
      id={id ?? "subActivity"}
      label={label ?? "Sub Activity"}
      placeholder="Select Sub Activity"
      options={items}
      {...form}
      disabled={form.disabled}
    />
  );
};

export default SubActivityPickerContainer;
