import { FC } from "react";
import { IResolveInspectionServiceOrderFormSchema } from "./schema";
import _ from "lodash";
import { wrapImage } from "utils";

const ServiceInfoSummary: FC<{
  data: IResolveInspectionServiceOrderFormSchema["serviceInfo"];
}> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className="">
      <span className="text-xs font-light">Demand Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div>
          <span className="block text-sm font-light text-gray-700">
            Tariff Class
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {_.startCase(data?.tariffClass?.name) || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Metering Type
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.loadType || "N/A"}
          </div>
        </div>
        <div className="col-span-1">
          <span className="block text-sm font-light text-gray-700">
            CT Meter Ratio
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.ctMeterRatio || "N/A"}
          </div>
        </div>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Closest Transformer Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.closestTransformerNumber || "N/A"}
          </div>
        </div>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Estimated Load
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.contractedDemand || "N/A"}
          </div>
        </div>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Closest Feeder Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.closestFeederNumber || "N/A"}
          </div>
        </div>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Feeder Load
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.closestFeederLoad || "N/A"}
          </div>
        </div>
        <div className="">
          <span className="block text-sm font-light text-gray-700">
            Feeder Voltage
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.closestFeederVoltage || "N/A"}
          </div>
        </div>
      </div>
    </div>
    <div className="pt-6">
      <span className="text-xs font-light">Transformer Test Information</span>
      <div className="grid grid-cols-3 gap-6 mt-2">
        <div className="col-span-3">
          <span className="text-xs font-light">Transformer Test Results</span>
          {data?.transformerTestResultDocumentUrl ? (
            <div className="mt-2">
              {wrapImage(
                <img
                  src={data?.transformerTestResultDocumentUrl}
                  alt={""}
                  className="w-full h-64 text-xs"
                />
              )}
            </div>
          ) : (
            <div className="border-2 border-dashed border-gray-300 rounded-md mt-2 h-64 items-center justify-center flex text-gray-600">
              Transformer test results not provided
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

export default ServiceInfoSummary;
