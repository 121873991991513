import { FC } from "react"
import { IResolveInstallationServiceOrderFormSchema } from "./schema"

const ServiceInfoSummary: FC<{ data: IResolveInstallationServiceOrderFormSchema["serviceInfo"] }> = ({ data }) => (
  <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3 space-y-6 divide-y divide-gray-200">
    <div className=''>
      <span className='text-xs font-light'>Meter Information</span>
      <div className='grid grid-cols-3 gap-6 mt-2'>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Transformer Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.transformerNumber || "N/A"}
          </div>
        </div>
        <div className="col-start-1">
          <span className="block text-sm font-light text-gray-700">
            Meter Number
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meter?.code || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Meter Brand
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meter?.model?.brand?.code || "N/A"} - {data?.meter?.model?.brand?.name || "N/A"}
          </div>
        </div>
        <div>
          <span className="block text-sm font-light text-gray-700">
            Meter Model
          </span>
          <div className="mt-1 block w-full sm:text-sm">
            {data?.meter?.model?.code || "N/A"} - {data?.meter?.model?.name || "N/A"}
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default ServiceInfoSummary