import { gql, useQuery } from "@apollo/client";
import {
  LineChart,
  Shimmers,
  StatsPageLoader,
} from "components";
import { FC, useMemo } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { getPeriod, useSummaryData } from "utils";
import numeral from "numeral";
import lodash from "lodash";
import moment from "moment";
import TableBodyComponent from "components/tables/table-body";
const DATE_FORMAT = "YYYY-MM-DD";

const SUMMARY = gql`
  query GET_SUMMARY(
    $fromDate: Date
    $toDate: Date
    $region: ID
    $district: ID
  ) {
    rows: getSummary(
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
    ) {
      _id
      date
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      newCustomers
      newCustomersIndividual
      newCustomersOrganization
      newCustomersPostpaid
      newCustomersPrepaid
      newCustomersAMR
      newCustomersResidential
      newCustomersNonResidential
      newCustomersIndustrial
      newCustomersStandard
      newCustomersExpress
      newCustomersPremium
      newCustomersTariffClasses
      lateSetups
      lateSetupsIndividual
      lateSetupsOrganization
      lateSetupsPostpaid
      lateSetupsPrepaid
      lateSetupsAMR
      lateSetupsResidential
      lateSetupsNonResidential
      lateSetupsIndustrial
      lateSetupsShep
      lateSetupsNotMigrated
      lateSetupsRegularization
      lateSetupsTariffClasses
      replacements
      replacementsPostpaidToPrepaid
      replacementsPrepaidToPrepaid
      replacementsPostpaidToPostpaid
      replacementsPrepaidToPostpaid
      replacementsPostpaidToAMR
      replacementsPrepaidToAMR
      replacementsAMRToAMR
      replacementsAMRToPrepaid
      replacementsAMRToPostpaid
      replacementsAdditionalLoad
      replacementsFaultyMeter
      replacementsStolenMeter
      replacementsMassReplacement
      complaintsNew
      complaintsResolved
      complaintsInProgress
      complaintsCancelled
      newReadings
      newReadingExceptions
      newReadingAnomalies
      newMeterEngagements
      newNoTraces
      newNoAccesses
      newUnlistedMeters
      consumptionBilled
      amountBilled
    }
  }
`;

const NewCustomersGeneralPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();

  const filter = useMemo(
    () => ({
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
    }),
    [searchParams]
  );

  const { data, loading, networkStatus } = useQuery(SUMMARY, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  const { keyedGraphData, records, totals } = useSummaryData(data);

  const reasonStats = useMemo(
    () => [
      {
        id: "additionalLoad",
        name: "Additional Load",
        value: lodash.get(totals, "replacementsAdditionalLoad"),
      },
      {
        id: "faultyMeter",
        name: "Faulty Meter",
        value: lodash.get(totals, "replacementsFaultyMeter"),
      },
      {
        id: "stolenMeter",
        name: "Stolen Meter",
        value: lodash.get(totals, "replacementsStolenMeter"),
      },
      {
        id: "massReplacement",
        name: "Mass Replacement",
        value: lodash.get(totals, "replacementsMassReplacement"),
      },
      {
        id: "total",
        name: "Total",
        value: lodash.get(totals, "replacements"),
      },
    ],
    [totals]
  );

  const typesStats = useMemo(
    () => [
      {
        id: "PrepaidToPrepaid",
        name: "Prepaid To Prepaid",
        value: lodash.get(totals, "replacementsPrepaidToPrepaid"),
      },
      {
        id: "PrepaidToPostpaid",
        name: "Prepaid To Postpaid",
        value: lodash.get(totals, "replacementsPrepaidToPostpaid"),
      },
      {
        id: "PrepaidToAMR",
        name: "Prepaid To AMR",
        value: lodash.get(totals, "replacementsPrepaidToAMR"),
      },
      {
        id: "PostpaidToPrepaid",
        name: "Postpaid To Prepaid",
        value: lodash.get(totals, "replacementsPostpaidToPrepaid"),
      },
      {
        id: "PostpaidToPostpaid",
        name: "Postpaid To Postpaid",
        value: lodash.get(totals, "replacementsPostpaidToPostpaid"),
      },
      {
        id: "PostpaidToAMR",
        name: "Postpaid To AMR",
        value: lodash.get(totals, "replacementsPostpaidToAMR"),
      },
      {
        id: "AMRToPrepaid",
        name: "AMR To Prepaid",
        value: lodash.get(totals, "replacementsAMRToPrepaid"),
      },
      {
        id: "AMRToPostpaid",
        name: "AMR To Postpaid",
        value: lodash.get(totals, "replacementsAMRToPostpaid"),
      },
      {
        id: "AMRToAMR",
        name: "AMR To AMR",
        value: lodash.get(totals, "replacementsAMRToAMR"),
      },
      {
        id: "total",
        name: "Total",
        value: lodash.get(totals, "replacements"),
      },
    ],
    [totals]
  );

  if(loading) {
    return <StatsPageLoader />
  }

  return (
    <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 px-6 overflow-hidden overflow-y-auto light grid grid-cols-2 gap-6">
      <div className="col-span-2">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Summary
          </h3>
          <div className="mt-3 ">
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-5">
              {reasonStats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white p-4 shadow"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="flex justify-between items-end">
                    <p className="text-xl font-light print:font-bold  text-gray-900">
                      {numeral(item.value).format("0,0")}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      {!searchParams.district && (
        <div className="flex-1">
          <div className="flex flex-col">
            <div className="overflow-y-auto">
              <TableBodyComponent
                title={`Replacements Per ${
                  searchParams?.region ? "District" : "Region"
                }`}
                exportFileName={`Replacements Per ${
                  searchParams?.region ? "District" : "Region"
                } For ${getPeriod(searchParams)}`}
                exportTypes={["EXCEL", "PDF"]}
                noSpace={true}
                loading={loading && ![4, 6].includes(networkStatus)}
                data={{
                  ...records,
                  rows: [
                    ...records.rows,
                    {
                      ...totals,
                      _id: "total",
                      region: {
                        _id: "total",
                        code: "total",
                        name: "Total",
                      },
                      district: {
                        _id: "total",
                        code: "total",
                        name: "Total",
                      },
                    },
                  ],
                }}
                renderColumns={() => (
                  <>
                    <tr className="hidden print:table-row">
                      <th
                        scope="col"
                        colSpan={2}
                        className="sticky top-0 border-t border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-center text-md font-bold text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Replacements Per{" "}
                        {searchParams?.region ? "District" : "Region"} For{" "}
                        {getPeriod(searchParams)}
                      </th>
                    </tr>
                    <tr>
                      {!!searchParams.region ? (
                        <th
                          scope="col"
                          className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          District
                        </th>
                      ) : (
                        <th
                          scope="col"
                          className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Region
                        </th>
                      )}
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Count
                      </th>
                    </tr>
                  </>
                )}
                renderLoader={() => (
                  <tr className="border-t border-gray-200 print:border-gray-700">
                    <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                      <Shimmers.SingleShimmer />
                    </td>
                    <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                      <Shimmers.SingleShimmer />
                    </td>
                  </tr>
                )}
                renderItem={(item) => (
                  <tr
                    key={item._id}
                    className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                  >
                    {!!searchParams.region ? (
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {item?.district?.name || "N/A"}
                      </td>
                    ) : (
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {item?.region?.name || "N/A"}
                      </td>
                    )}
                    <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                      {numeral(item?.replacements).format("0,0") || "N/A"}
                    </td>
                  </tr>
                )}
              />
            </div>
          </div>
        </div>
      )}
      <div className="col-span-1 mt-3">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Replacements Per Replacement Type
          </h3>
          <div className="mt-3 ">
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2">
              {typesStats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white p-4 shadow"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="flex justify-between items-end">
                    <p className="text-xl font-light print:font-bold  text-gray-900">
                      {numeral(item.value).format("0,0")}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="col-span-2">
        <LineChart
          title="Replacements Per Day"
          exportFileName={`Replacements For ${getPeriod(searchParams)}`}
          label=""
          keys={[
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "total",
              label: "Total Replacements",
            },
          ]}
          data={lodash.times(
            moment(searchParams.toDate, DATE_FORMAT).diff(
              moment(searchParams.fromDate, DATE_FORMAT),
              "days"
            ) + 1,
            (idx) => {
              const currentDate = moment(searchParams.fromDate)
                .add(idx, "days")
                .format(DATE_FORMAT);
              return {
                value: {
                  total: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacements`,
                    0
                  ),
                },
                day: currentDate,
              };
            }
          )}
        />
      </div>
    </div>
  );
};

export default NewCustomersGeneralPage;
