import { gql, useQuery } from "@apollo/client";

const GET_INSTALLATION_SERVICE_ORDERS = gql`
  query GetHassleFreeOrders(
    $page: Int
    $pageSize: Int
    $sort: String
    $search: String
    $searchFields: [String!]
    $region: ID
    $district: ID
    $status: InstallationServiceOrderStatus
    $dateField: String
    $fromDate: Date
    $toDate: Date
  ) {
    rows: getInstallationServiceOrders(
      page: $page
      pageSize: $pageSize
      sort: $sort
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      dateField: $dateField
      fromDate: $fromDate
      toDate: $toDate
    ) {
      _id
      code
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      contactPerson {
        fullName
        phoneNumber
      }
      servicePoint {
        _id
        code
        geoCode
        tariffClass {
          _id
          code
          name
        }
      }
      request {
        _id
        code
        type
        category
        connectionFee {
          _id
          paidAt
          amount
        }
      }
      installedMeter {
        _id
        code
        modelMeta {
          brandCode
          brandName
          systemCode
          systemName
          modelCode
          modelName
          phase
        }
      }
      resolution {
        reading {
          readingDate
        }
      }
      completedAt
      createdAt
    }
    count: getInstallationServiceOrdersCount(
      search: $search
      searchFields: $searchFields
      region: $region
      district: $district
      status: $status
      dateField: $dateField
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;

export const useInstallationServiceOrders = (variables: any) => {
  const { data, ...rest } = useQuery(GET_INSTALLATION_SERVICE_ORDERS, {
    variables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-and-network",
  });
  const installationServiceOrders = data?.rows || [];
  const count = data?.count || 0;
  return { installationServiceOrders, count, ...rest };
};
