import { gql, useQuery } from "@apollo/client";
import {
  BarChart,
  LineChart,
  PieChart,
  Shimmers,
  StatsPageLoader,
  TableComponent,
} from "components";
import { FC, useMemo } from "react";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { getPeriod, useSummaryData, useTableData } from "utils";
import numeral from "numeral";
import lodash from "lodash";
import moment from "moment";
import TableBodyComponent from "components/tables/table-body";
const DATE_FORMAT = "YYYY-MM-DD";

const SUMMARY = gql`
  query GET_SUMMARY(
    $fromDate: Date
    $toDate: Date
    $region: ID
    $district: ID
  ) {
    rows: getSummary(
      fromDate: $fromDate
      toDate: $toDate
      region: $region
      district: $district
    ) {
      _id
      date
      region {
        _id
        code
        name
      }
      district {
        _id
        code
        name
      }
      newCustomers
      newCustomersIndividual
      newCustomersOrganization
      newCustomersPostpaid
      newCustomersPrepaid
      newCustomersAMR
      newCustomersResidential
      newCustomersNonResidential
      newCustomersIndustrial
      newCustomersStandard
      newCustomersExpress
      newCustomersPremium
      newCustomersTariffClasses
      lateSetups
      lateSetupsIndividual
      lateSetupsOrganization
      lateSetupsPostpaid
      lateSetupsPrepaid
      lateSetupsAMR
      lateSetupsResidential
      lateSetupsNonResidential
      lateSetupsIndustrial
      lateSetupsShep
      lateSetupsNotMigrated
      lateSetupsRegularization
      lateSetupsTariffClasses
      replacements
      replacementsPostpaidToPrepaid
      replacementsPrepaidToPrepaid
      replacementsPostpaidToPostpaid
      replacementsPrepaidToPostpaid
      replacementsPostpaidToAMR
      replacementsPrepaidToAMR
      replacementsAMRToAMR
      replacementsAMRToPrepaid
      replacementsAMRToPostpaid
      complaintsNew
      complaintsResolved
      complaintsInProgress
      complaintsCancelled
      newReadings
      newReadingExceptions
      newReadingAnomalies
      newMeterEngagements
      newNoTraces
      newNoAccesses
      newUnlistedMeters
      consumptionBilled
      amountBilled
    }
  }
`;

const NewCustomersServiceClassPage: FC = () => {
  const searchParams = useSearch<LocationGenerics>();

  const filter = useMemo(
    () => ({
      region: searchParams.region || undefined,
      district: searchParams.district || undefined,
      fromDate: moment(searchParams.fromDate, DATE_FORMAT)
        .startOf("day")
        .toDate(),
      toDate: moment(searchParams.toDate, DATE_FORMAT).startOf("day").toDate(),
    }),
    [searchParams]
  );

  const { data, loading, networkStatus } = useQuery(SUMMARY, {
    variables: filter,
    notifyOnNetworkStatusChange: false,
  });

  const { keyedGraphData, records, totals } = useSummaryData(data);

  const stats = useMemo(
    () => [
      {
        id: "PrepaidToPrepaid",
        name: "Prepaid To Prepaid",
        value: lodash.get(totals, "replacementsPrepaidToPrepaid"),
      },
      {
        id: "PrepaidToPostpaid",
        name: "Prepaid To Postpaid",
        value: lodash.get(totals, "replacementsPrepaidToPostpaid"),
      },
      {
        id: "PrepaidToAMR",
        name: "Prepaid To AMR",
        value: lodash.get(totals, "replacementsPrepaidToAMR"),
      },
      {
        id: "PostpaidToPrepaid",
        name: "Postpaid To Prepaid",
        value: lodash.get(totals, "replacementsPostpaidToPrepaid"),
      },
      {
        id: "PostpaidToPostpaid",
        name: "Postpaid To Postpaid",
        value: lodash.get(totals, "replacementsPostpaidToPostpaid"),
      },
      {
        id: "PostpaidToAMR",
        name: "Postpaid To AMR",
        value: lodash.get(totals, "replacementsPostpaidToAMR"),
      },
      {
        id: "AMRToPrepaid",
        name: "AMR To Prepaid",
        value: lodash.get(totals, "replacementsAMRToPrepaid"),
      },
      {
        id: "AMRToPostpaid",
        name: "AMR To Postpaid",
        value: lodash.get(totals, "replacementsAMRToPostpaid"),
      },
      {
        id: "AMRToAMR",
        name: "AMR To AMR",
        value: lodash.get(totals, "replacementsAMRToAMR"),
      },
      {
        id: "total",
        name: "Total",
        value: lodash.get(totals, "replacements"),
      },
    ],
    [totals]
  );

  if(loading) {
    return <StatsPageLoader />
  }

  return (
    <div className="flex-1 min-h-full mx-auto min-w-0  py-4 sm:py-6 px-6 overflow-hidden overflow-y-auto light flex flex-col">
      <div className="">
        <div className="flex flex-col">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Summary
          </h3>
          <div className="mt-3 ">
            <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-5">
              {stats.map((item) => (
                <div
                  key={item.id}
                  className="relative overflow-hidden rounded-lg bg-white p-4 shadow"
                >
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="flex justify-between items-end">
                    <p className="text-xl font-light print:font-bold  text-gray-900">
                      {numeral(item.value).format("0,0")}
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      {!searchParams.district && (
        <div className="flex flex-row mt-6 space-x-6">
          <div className="flex-1 overflow-x-auto">
            <div className="flex flex-col">
              <div className="overflow-y-auto">
                <TableBodyComponent
                  title={`Replacements Per Replacement Type Per ${
                    searchParams?.region ? "District" : "Region"
                  }`}
                  exportFileName={`Replacements By Replacement Type For ${getPeriod(
                    searchParams
                  )}`}
                  exportTypes={["EXCEL", "PDF"]}
                  noSpace={true}
                  loading={loading && ![4, 6].includes(networkStatus)}
                  data={{
                    ...records,
                    rows: [
                      ...records.rows,
                      {
                        ...totals,
                        _id: "total",
                        region: {
                          _id: "total",
                          code: "total",
                          name: "Total"
                        },
                        district: {
                          _id: "total",
                          code: "total",
                          name: "Total"
                        },
                      }
                    ]
                  }}
                  renderColumns={() => (
                    <>
                      <tr className="hidden print:table-row">
                        <th
                          scope="col"
                          colSpan={11}
                          className="sticky top-0 border-t border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-center text-md font-bold text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Replacements By Replacement Type For{" "}
                          {getPeriod(searchParams)}
                        </th>
                      </tr>
                    <tr>
                      {!!searchParams.region ? (
                        <th
                          scope="col"
                          className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          District
                        </th>
                      ) : (
                        <th
                          scope="col"
                          className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-left text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                        >
                          Region
                        </th>
                      )}
                      <th
                        scope="col"
                        className="cursor-pointer sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Prepaid To Prepaid
                      </th>
                      <th
                        scope="col"
                        className="cursor-pointer sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Prepaid To Postpaid
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Prepaid To AMR
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Postpaid To Prepaid
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Postpaid To Postpaid
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Postpaid To AMR
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        AMR To Prepaid
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        AMR To Postpaid
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        AMR To AMR
                      </th>
                      <th
                        scope="col"
                        className="sticky top-0 border-y border-gray-200 print:border-gray-700 bg-gray-50 dark:bg-gray-800 px-4 py-3 text-right text-xs font-light print:font-bold  text-gray-900 dark:text-gray-100 uppercase tracking-wider whitespace-nowrap"
                      >
                        Total
                      </th>
                    </tr>
                    </>
                  )}
                  renderLoader={() => (
                    <tr className="border-t border-gray-200 print:border-gray-700">
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                      <td className="px-4 py-4 border-b border-gray-200 print:border-gray-700">
                        <Shimmers.SingleShimmer />
                      </td>
                    </tr>
                  )}
                  renderItem={(item) => (
                    <tr
                      key={item._id}
                      className="hover:bg-gray-50 dark:hover:bg-gray-800 cursor-pointer"
                    >
                      {!!searchParams.region ? (
                        <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                          {item?.district?.name || "N/A"}
                        </td>
                      ) : (
                        <td className="whitespace-nowrap px-4 py-4 text-normal text-gray-700 border-b border-gray-200 print:border-gray-700">
                          {item?.region?.name || "N/A"}
                        </td>
                      )}
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacementsPrepaidToPrepaid).format(
                          "0,0"
                        ) || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacementsPrepaidToPostpaid).format(
                          "0,0"
                        ) || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacementsPrepaidToAMR).format(
                          "0,0"
                        ) || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacementsPostpaidToPrepaid).format(
                          "0,0"
                        ) || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacementsPostpaidToPostpaid).format(
                          "0,0"
                        ) || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacementsPostpaidToAMR).format(
                          "0,0"
                        ) || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacementsAMRToPrepaid).format(
                          "0,0"
                        ) || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacementsAMRToPostpaid).format(
                          "0,0"
                        ) || "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacementsAMRToAMR).format("0,0") ||
                          "N/A"}
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-normal text-right text-gray-700 border-b border-gray-200 print:border-gray-700">
                        {numeral(item?.replacements).format("0,0") || "N/A"}
                      </td>
                    </tr>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-6">
        <LineChart
          title="Replacements Per Replacement Type Per Day"
          exportFileName={`Replacements By Replacement Type For ${getPeriod(
            searchParams
          )}`}
          label="Replacement Type"
          loading={loading && ![4, 6].includes(networkStatus)}
          keys={[
            {
              color: "#2563eb",
              bgColor: "bg-blue-600",
              id: "PrepaidToPrepaid",
              label: "Prepaid To Prepaid",
            },
            {
              color: "#0d9488",
              bgColor: "bg-teal-600",
              id: "PrepaidToPostpaid",
              label: "Prepaid To Postpaid",
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "PrepaidToAMR",
              label: "Prepaid To AMR",
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "PostpaidToPrepaid",
              label: "Postpaid To Prepaid",
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "PostpaidToPostpaid",
              label: "Postpaid To Postpaid",
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "PostpaidToAMR",
              label: "Postpaid To AMR",
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "AMRToPrepaid",
              label: "AMR To Prepaid",
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "AMRToPostpaid",
              label: "AMR To Postpaid",
            },
            {
              color: "#9333ea",
              bgColor: "bg-purple-600",
              id: "AMRToAMR",
              label: "AMR To AMR",
            },
          ]}
          data={lodash.times(
            moment(searchParams.toDate, DATE_FORMAT).diff(
              moment(searchParams.fromDate, DATE_FORMAT),
              "days"
            ) + 1,
            (idx) => {
              const currentDate = moment(searchParams.fromDate)
                .add(idx, "days")
                .format(DATE_FORMAT);
              return {
                value: {
                  PrepaidToPrepaid: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacementsPrepaidToPrepaid`,
                    0
                  ),
                  PrepaidToPostpaid: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacementsPrepaidToPostpaid`,
                    0
                  ),
                  PrepaidToAMR: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacementsPrepaidToAMR`,
                    0
                  ),
                  PostpaidToPrepaid: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacementsPostpaidToPrepaid`,
                    0
                  ),
                  PostpaidToPostpaid: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacementsPostpaidToPostpaid`,
                    0
                  ),
                  PostpaidToAMR: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacementsPostpaidToAMR`,
                    0
                  ),
                  AMRToPrepaid: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacementsAMRToPrepaid`,
                    0
                  ),
                  AMRToPostpaid: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacementsAMRToPostpaid`,
                    0
                  ),
                  AMRToAMR: lodash.get(
                    keyedGraphData,
                    `${currentDate}.replacementsAMRToAMR`,
                    0
                  ),
                },
                day: currentDate,
              };
            }
          )}
        />
      </div>
    </div>
  );
};

export default NewCustomersServiceClassPage;
