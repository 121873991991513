import { FC } from "react";
import moment from "moment";
import { useReactiveVar } from "@apollo/client";
import { currentConfigVar } from "apollo/cache/config";
import { Avatar } from "components/core";
import { classNames } from "utils";
import _ from "lodash";
import lodash from "lodash";

interface InstallationServiceOrderViewProps {
  installationServiceOrder: {
    servicePoint: any;
    installationType: {
      code: string;
      name: string;
    };
    installationMeter: {
      modelMeta: {
        phase: string;
      };
      code: string;
    };
    farmingOutContractor: {
      _id: string;
      code: string;
      name: string;
      address: string;
      ghanaPostAddress: string;
      type: string;
    };
    meterContractor: {
      _id: string;
      code: string;
      name: string;
      address: string;
      ghanaPostAddress: string;
      type: string;
    };
    assigneeType: string;
    request: {
      response: {
        servicePoint: {
          code: string;
        };
      };
      resolution: {
        service: {
          meterPhase: string;
        };
      };
    };
    category: string | undefined;
    code: string;
    status: string;
    type: string;
    priority: string;
    region: {
      code: string;
      name: string;
    };
    district: {
      code: string;
      name: string;
    };
    contactPerson: {
      fullName: string;
      phoneNumber: string;
    };
    location: {
      address: string;
      community: string;
    };
    assigner: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    assignee: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    approver: {
      _id: string;
      code: string;
      lastName: string;
      firstName: string;
      profileImageUrl: string;
      phoneNumber: string;
      emailAddress: string;
    };
    estimatedResolutionDate: string;
    createdAt: string;
    updatedAt: string;
  };
}

function Owner({
  owner,
  type,
}: {
  owner: any;
  type: "field-agents" | "staff-accounts";
}) {
  return (
    <div
      className={classNames(
        "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
        "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
      )}
    >
      <div className="flex-shrink-0">
        <Avatar
          alt={
            [
              (owner?.lastName || "")?.trim(),
              (owner?.firstName || owner?.name || "")?.trim(),
            ]
              .join(" ")
              .trim() || "N A"
          }
          src={owner?.profileImageUrl || ""}
        />
      </div>
      <div className="flex-1 min-w-0 text-left">
        <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
          {[
            (owner?.lastName || "")?.trim(),
            (owner?.firstName || owner?.name || "")?.trim(),
          ]
            .join(" ")
            .trim() || "N A"}
        </p>
        <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
          {owner?.phoneNumber || owner?.code || "N/A"}
        </p>
      </div>
    </div>
  );
}

const InstallationServiceOrderView: FC<InstallationServiceOrderViewProps> = ({
  installationServiceOrder,
}) => {
  const { dateFormat } = useReactiveVar(currentConfigVar);

  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <span className="text-xs font-light">Service Order Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">Code</span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">Date</span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.createdAt
                ? moment(installationServiceOrder.createdAt).format(dateFormat)
                : "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Category
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(installationServiceOrder?.category) || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Status
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(installationServiceOrder?.status) || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Priority
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {_.startCase(installationServiceOrder?.priority) || "N/A"}
            </div>
          </div>
          <div className="">
            <span className="block text-sm font-light text-gray-700">
              Estimated Resolution Date
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.estimatedResolutionDate
                ? moment(
                    installationServiceOrder.estimatedResolutionDate
                  ).format(dateFormat)
                : "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Installation Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Installation Type
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.installationType?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Service Point Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.servicePoint
                ?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.installationMeter?.code || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Meter Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.installationMeter?.modelMeta?.phase ||
                "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Contact Person Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Full Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.contactPerson?.fullName || "N/A"}
            </div>
          </div>

          <div>
            <span className="block text-sm font-light text-gray-700">
              Phone Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.contactPerson?.phoneNumber || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Location Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Region
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.region?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              District
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.district?.name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Community
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.location?.community || "N/A"}
            </div>
          </div>

          <div className="col-span-3">
            <span className="block text-sm font-light text-gray-700">
              Address
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {installationServiceOrder?.location?.address || "N/A"}
            </div>
          </div>
        </div>
      </div>
      {installationServiceOrder?.status !== "Pending" && (
        <div className="pt-6">
          <span className="text-xs font-light">Actors Information</span>
          <div className="grid grid-cols-2 gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Assigner
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                <Owner
                  owner={installationServiceOrder?.assigner}
                  type="staff-accounts"
                />
              </div>
            </div>
            <div>
              <span className="block text-sm font-light text-gray-700">
                Assignee (
                {lodash.startCase(installationServiceOrder?.assigneeType)})
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                <Owner
                  owner={
                    installationServiceOrder?.assignee ||
                    installationServiceOrder?.meterContractor ||
                    installationServiceOrder?.farmingOutContractor
                  }
                  type="field-agents"
                />
              </div>
            </div>
            {/* {installationServiceOrder.status === "Completed" && (
              <div>
                <span className="block text-sm font-light text-gray-700">
                  Approver
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  <Owner
                    owner={installationServiceOrder?.approver}
                    type="staff-accounts"
                  />
                </div>
              </div>
            )} */}
          </div>
        </div>
      )}
    </div>
  );
};

export default InstallationServiceOrderView;
