import { Route } from "react-location";
import {
  DashboardPage,
  ForgotPasswordPage,
  SigninPage,
  AuthPage,
  CustomerComplaintsPage,
  CustomerComplaintsSummaryPage,
  CustomersPage,
  ReplacementsSummaryPage,
  ReplacementsReasonsPage,
  ReplacementsTypesPage,
  ReplacementsPage,
  CustomersSummaryPage,
  CustomersServiceClassesPage,
  CustomersServiceTypesPage,
  CustomersTariffClassesPage,
  CustomersCustomerTypesPage,
  InspectionsPage,
  InstallationsPage,
  HassleFreePage,
  CustomersMeterTypesPage,
  CustomersStatusesPage,
  CustomersCategoriesPage,
} from "pages";
import {
  LifebuoyIcon,
  UserCircleIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { UserPermission } from "apollo/cache/auth";
import { TabbedOfficeHeader } from "components";
import CalendarLayout from "layouts/calendar-wrapper";
import ShepRegularizationsPage from "pages/shep-regularizations";
import ServiceRegularizationsPage from "pages/service-regularizations";

export type RouteProps = Omit<Route, "children"> & {
  withPermissions?: UserPermission[];
  navigation?: boolean;
  sidebar?: { label: string; icon: any; section?: string };
  children?: RouteProps[];
};

const routes: RouteProps[] = [
  {
    path: "/",
    element: <DashboardPage />,
    sidebar: {
      label: "Dashboard",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Dashboard",
      section: "Dashboard",
    },
  },
  {
    path: "customers",
    element: <TabbedOfficeHeader />,
    sidebar: {
      label: "All Customers",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "All Customers",
      section: "Customer Population",
    },
    children: [
      {
        id: "summary",
        search: (search) => {
          return search.tab === "summary";
        },
        element: <CustomersSummaryPage />,
        sidebar: {
          label: "Summary",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Summary",
          section: "Customer Population",
        },
      },
      {
        id: "statuses",
        search: (search) => {
          return search.tab === "statuses";
        },
        element: <CustomersStatusesPage />,
        sidebar: {
          label: "Contract Statuses",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Contract Statuses",
          section: "Customer Population",
        },
      },
      {
        id: "categories",
        search: (search) => {
          return search.tab === "categories";
        },
        element: <CustomersCategoriesPage />,
        sidebar: {
          label: "Categories",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Categories",
          section: "Customer Population",
        },
      },
      {
        id: "service-classes",
        search: (search) => {
          return search.tab === "service-classes";
        },
        element: <CustomersServiceClassesPage />,
        sidebar: {
          label: "Service Classes",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Service Classes",
          section: "Customer Population",
        },
      },
      {
        id: "service-types",
        search: (search) => {
          return search.tab === "service-types";
        },
        element: <CustomersServiceTypesPage />,
        sidebar: {
          label: "Service Types",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Service Types",
          section: "Customer Population",
        },
      },
      {
        id: "tariff-classes",
        search: (search) => {
          return search.tab === "tariff-classes";
        },
        element: <CustomersTariffClassesPage />,
        sidebar: {
          label: "Tariff Classes",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Tariff Classes",
          section: "Customer Population",
        },
      },
      {
        id: "customer-types",
        search: (search) => {
          return search.tab === "customer-types";
        },
        element: <CustomersCustomerTypesPage />,
        sidebar: {
          label: "Customer Types",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Customer Types",
          section: "Customer Population",
        },
      },
      {
        id: "meter-types",
        search: (search) => {
          return search.tab === "meter-types";
        },
        element: <CustomersMeterTypesPage />,
        sidebar: {
          label: "Meter Types",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Meter Types",
          section: "Customer Population",
        },
      },
      {
        id: "customer-data",
        search: (search) => {
          return search.tab === "customer-data";
        },
        element: <CustomersPage />,
        sidebar: {
          label: "Customer Data",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Customer Data",
          section: "Customer Population",
        },
      },
    ],
  },
  {
    path: "replacements",
    element: <TabbedOfficeHeader />,
    sidebar: {
      label: "Replacements",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Replacements",
      section: "Customer Population",
    },
    children: [
      {
        id: "summary",
        search: (search) => {
          return search.tab === "summary";
        },
        element: <ReplacementsSummaryPage />,
        sidebar: {
          label: "Summary",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Summary",
          section: "Customer Population",
        },
      },
      {
        id: "replacement-reasons",
        search: (search) => {
          return search.tab === "replacement-reasons";
        },
        element: <ReplacementsReasonsPage />,
        sidebar: {
          label: "Replacement Reasons",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Replacement Reasons",
          section: "Customer Population",
        },
      },
      {
        id: "replacement-types",
        search: (search) => {
          return search.tab === "replacement-types";
        },
        element: <ReplacementsTypesPage />,
        sidebar: {
          label: "Replacement Types",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Replacement Types",
          section: "Customer Population",
        },
      },
      {
        id: "replacements-data",
        search: (search) => {
          return search.tab === "replacements-data";
        },
        element: <ReplacementsPage />,
        sidebar: {
          label: "Replacements Data",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Replacements Data",
          section: "Customer Population",
        },
      },
    ],
  },
  {
    path: "pending-inspections",
    element: <InspectionsPage />,
    sidebar: {
      label: "Pending Inspections",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Pending Inspections",
      section: "Contracting Trend",
    },
  },
  {
    path: "pending-installations",
    element: <InstallationsPage />,
    sidebar: {
      label: "Pending Installations",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Pending Installations",
      section: "Contracting Trend",
    },
  },
  {
    path: "contracting-ageing",
    element: <HassleFreePage />,
    sidebar: {
      label: "Hassle Free",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Hassle Free",
      section: "Contracting Trend",
    },
  },
  {
    path: "customer-complaints",
    element: <TabbedOfficeHeader />,
    sidebar: {
      label: "Customer Complaints",
      icon: UsersIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Customer Complaints",
      section: "Customer Service",
    },
    children: [
      {
        id: "summary",
        search: (search) => {
          return search.tab === "summary";
        },
        element: <CustomerComplaintsSummaryPage />,
        sidebar: {
          label: "Summary",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Summary",
          section: "Customer Service",
        },
      },
      {
        id: "complaints-data",
        search: (search) => {
          return search.tab === "complaints-data";
        },
        element: <CustomerComplaintsPage />,
        sidebar: {
          label: "Complaints Data",
          icon: UsersIcon,
        },
        meta: {
          layout: "App",
          breadcrumb: () => "Complaints Data",
          section: "Customer Service",
        },
      },
    ],
  },
  {
    path: "shep-regularizations",
    element: <CalendarLayout />,
    sidebar: {
      label: "Shep Captures",
      icon: LifebuoyIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Shep Captures",
      section: "Customer Service",
    },
    children: [
      {
        path: "/",
        element: <ShepRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Customer Service",
        },
      },
    ],
    withPermissions: ["*:*", "service-requests:*", "service-requests:manage"],
  },
  {
    path: "service-regularizations",
    element: <CalendarLayout />,
    sidebar: {
      label: "Regularizations",
      icon: UserCircleIcon,
    },
    meta: {
      layout: "App",
      breadcrumb: () => "Regularizations",
      section: "Customer Service",
    },
    children: [
      {
        path: "/",
        element: <ServiceRegularizationsPage />,
        meta: {
          layout: "App",
          section: "Customer Service",
        },
      },
      // {
      //   path: ":serviceRegularization",
      //   element: <ServiceRegularizationPage />,
      //   meta: {
      //     layout: "App",
      //     breadcrumb: () => "Service Regularization",
      //     section: "Faults And Complaints",
      //   },
      // },
    ],
    //TODO: Verify and enable permisions
    withPermissions: ["*:*", "service-requests:*", "service-requests:manage"],
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Forgot Password",
    },
  },
  {
    path: "signin",
    element: <SigninPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Signin",
    },
  },
  {
    path: "auth",
    element: <AuthPage />,
    meta: {
      layout: "Auth",
      breadcrumb: () => "Auth",
    },
  },
];

export default routes;
